<template>
  <BaseListLayout
    ref="listLayout"
    title="Organization"
    :table-header="headers"
    :table-data="companyList"
    :hasListActions="true"
    :total="totalItems"
    @getList="getCompanyList"
    :context="context"
    id="companySearch"
    name="companySearch"
  >
    <template #listAction>
      <BaseButton
        id="companyAddBtn"
        small
        customClass="font-weight-bold rounded-lg mr-2"
        @click="addCompany()"
        >Add Organization</BaseButton
      >
      <BaseButton
        id="companyBulkUploadBtn"
        small
        customClass="font-weight-bold rounded-lg"
        @click="showBulkUploadDialog = true"
        >Bulk Upload Organization</BaseButton
      >
    </template>
    <template #leftFilterSlot>
      <BaseRefreshList
        id="refreshList"
        name="refreshList"
        :isIcon="true"
        newClass="ml-2 mr-2"
        @click="getCompanyList({ offset: 0, limit: 10 })"
      ></BaseRefreshList>

      <BaseButton
        id="filterBtn"
        x-small
        fab
        depressed
        customClass="rounded-lg btn-small light_color_actions"
        icon="mdi-filter"
        iconColor="primary"
        iconClass="ma-1"
        iconSize="17px"
        @click="companyFilterDialog = true"
      ></BaseButton>
    </template>

    <template #dialogs>
      <CompanyEditForm
        ref="companyEditForm"
        v-model="openEditCompanyForm"
        @refreshList="getCompanyList({ offset: 0, limit: 10 })"
      ></CompanyEditForm>

      <BulkUpload
        uploadTo="company"
        v-model="showBulkUploadDialog"
        :hasBulkActions="true"
        @refreshList="getCompanyList({ limit: 10, offset: 0 })"
      />
      <CompanyFilter
        v-model="companyFilterDialog"
        @companyFiltersChanged="$refs.listLayout.searchTriggered()"
      ></CompanyFilter>
    </template>
  </BaseListLayout>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout.vue";
import CompanyActionsButton from "@/components/AgGridButtons/CompanyActionsButton.vue";
import CompanyFilter from "@/components/ModuleBasedComponents/company/CompanyFilter.vue";
import CompanyEditForm from "@/components/ModuleBasedComponents/company/CompanyEditForm.vue";
import BulkUpload from "@/components/BulkUploadComponents/BulkUpload.vue";
import { bus } from "@/main";
export default {
  components: {
    BaseListLayout,
    CompanyActionsButton,
    CompanyFilter,
    CompanyEditForm,
    BulkUpload,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      headers: [
        {
          headerName: "Organization ID",
          field: "company_id",
        },
        {
          headerName: "Organization Name",
          field: "name",
        },
        {
          headerName: "Project",
          field: "project_name",
        },
        {
          headerName: "Contact No",
          field: "contact_number",
        },
        {
          headerName: "Contact Email",
          field: "contact_email",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "CompanyActionsButton",
        },
      ],
      companyList: [],
      openEditCompanyForm: false,
      showBulkUploadDialog: false,
      companyFilterDialog: false,
      filters: {},
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getCompanyList(params = {}) {
      let filters = localStorage.getItem("companyFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if (filters.project_id && filters.project_id.length > 0) {
        filters.project_id = filters.project_id.join(",");
      }
      this.filters = filters;
      params = {
        ...params,
        ...this.filters,
      };
      bus.$emit("showLoader", true);
      this.$api.company
        .getCompanyList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.companyList = res?.data;
          this.totalItems = res?.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    headersChanged(value) {
      this.headerSelected = value;
    },

    addCompany() {
      this.$router.push("/app/admin/company/create");
    },

    editCompany(id) {
      this.openEditCompanyForm = true;
      this.$refs.companyEditForm.getCompanyObject(id);
    },

    viewCompanyDetails(id) {
      this.$router.push(`/app/admin/company/detail/${id}`);
    },
  },
};
</script>

<style>
</style>