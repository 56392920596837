<template>
  <v-card tile class="elevation-0">
    <v-card-title class="px-4 py-0 d-flex justify-center" style="height: 80px">
      <v-row>
        <v-col cols="1" class="d-flex justify-end"> </v-col>
        <v-col cols="4" class="d-flex justify-start pa-0">
          <h4
            class="text-body-2 font-weight-bold light_grey--text text-uppercase"
          >
            Stops & Passengers
          </h4>
        </v-col>
      </v-row>

      <slot name="otherInformation"></slot>
    </v-card-title>
    <v-card-text class="ma-0 pa-0">
      <v-row no-gutters class="ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">
          <v-timeline dense class="ma-0 pa-0">
            <v-timeline-item
              small
              v-for="(stop, index) in stops"
              :key="index"
              class="ma-0 pa-0 d-flex justify-start"
              :color="
                index == 0
                  ? '#50CD89'
                  : index === stops.length - 1
                  ? '#F15050'
                  : 'primary'
              "
            >
              <v-card class="elevation-0">
                <v-card-text class="ma-0 pa-2">
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col
                      cols="12"
                      class="ma-0 pa-0 d-flex justify-start align-center"
                    >
                      <span class="text-body-2 font-weight-bold black--text">
                        {{ stop.eta }}
                      </span>
                      <span> &nbsp; | &nbsp; </span>
                      <span class="text-body-2 font-weight-bold black--text">
                        {{ stop.address }}
                      </span>
                    </v-col>
                    <v-col
                      cols="12"
                      class="ma-0 pa-0 d-flex justify-start align-center"
                    >
                      <span
                        class="text-caption font-weight-medium black--text"
                        >{{ stop.employee_names }}</span
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    stops: {
      type: Array,
      required: true,
    },
  },
  components: {},
};
</script>
