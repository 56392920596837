<template>
  <div class="login-main">
    <v-img
      :src="$globalConstant.loginBackGroundLogo"
      class="login-bg-image"
    ></v-img>

    <v-row style="width: 100% !important; height: 100vh">
      <v-col
        cols="12"
        md="7"
        class="d-none d-md-flex flex-column justify-space-between"
      >
      </v-col>
      <v-col
        cols="12"
        md="5"
        class="d-flex justify-center align-center"
        style="width: 100% !important; height: 100% !important"
      >
        <div
          class="py-12 pl-10 d-flex justify-center align-center"
          style="width: 100% !important; height: 100% !important"
        >
          <v-card
            class="px-12 py-12 align-center"
            style="max-width: 450px; min-width: 350px"
          >
            <v-card-title class="d-flex justify-center">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="pa-0 ma-0 d-flex justify-center">
                  <v-img
                    :src="$globalConstant.logo"
                    contain
                    style="height: 50%; width: 50%"
                  ></v-img>
                </v-col>
              </v-row>
              <h3 class="text-center">Passenger Transportation made easy</h3>
            </v-card-title>
            <v-card-title class="d-flex justify-center pt-6 pb-12">
              <h3>Sign In</h3>
            </v-card-title>
            <v-card-text>
              <v-form
                v-model="isValid"
                ref="loginForm"
                @submit.prevent="submitForm"
              >
                <v-row class="justify-center">
                  <v-col cols="pt-8 pb-6 ma-0">
                    <v-row>
                      <v-col cols="12">
                        <BaseTextInput
                          v-model.trim="userDetails.username"
                          prepend-inner-icon="mdi-account-circle"
                          name="username"
                          id="username"
                          label="Username"
                          :rules="[(v) => !!v || 'Username is required']"
                          :error-messages="formError.username"
                          @input="delete formError.username"
                        ></BaseTextInput>
                      </v-col>

                      <v-col cols="12">
                        <BasePasswordInput
                          label="Password"
                          name="password"
                          id="password"
                          v-model="userDetails.password"
                          :rules="[(v) => !!v || 'Password is required']"
                          :error-messages="formError.password"
                          @input="delete formError.password"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <BaseButton
                      id="loginBtn"
                      depressed
                      customClass="rounded-lg"
                      @click="onSubmit()"
                      :disabled="!isValid"
                      small
                    >
                      Login
                    </BaseButton>
                  </v-col>
                  <!-- <v-col cols="12" class="d-flex justify-center">
                    <v-btn
                      @click="forgotPassword()"
                      text
                      type="button"
                      color="primary"
                      depressed
                      large
                      class="text-capitalize"
                    >
                      Forgot Password ?
                    </v-btn>
                  </v-col> -->
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { bus } from "@/main.js";
export default {
  name: "mainLogin",
  layout: "authentication",
  data() {
    return {
      viewPassword: false,
      username: "",
      password: "",
      loading: false,
      userDetails: {},
      formError: {},
      isValid: true,
      error: {
        username: null,
        password: null,
      },
    };
  },
  computed: {
    mapHeight() {
      return window.innerWidth;
    },
  },
  methods: {
    checkIfLoggedIn() {
      let user = localStorage.getItem("user");
      if (!user) {
        if (this.$route.path !== "/app/login") {
          this.$router.push({ path: "/app/login" });
        }
      } else {
        if (this.$route.fullPath == "/app") {
          this.$router.push({ path: "/app/admin" });
        }
        // if (["/app", "/app/admin"].indexOf(this.$route.fullPath) > -1) {
        //   this.$router.push({ path: "/app/admin/dashboard" });
        // }
      }
    },
    onSubmit() {
      this.loading = true;
      this.$api.auth
        .login(this.userDetails)
        .then((success) => {
          this.loading = false;
          localStorage.setItem("user", success?.data?.token);
          this.$router.push("/app/admin/dashboard");
          // localStorage.setItem("user", success.data.token);
          // if (success && success.data && success.data.user_type == "driver") {
          //   this.$api.driverApp.getDriverDetails().then((result) => {
          //     if (result.trip != null) {
          //       this.getTripDetails(result.trip);
          //     }
          //     this.$router.push("/driver_app");
          //   });
          // } else {
          //   this.$api.profile
          //     .getUserProfile()
          //     .then((result) => {
          //       localStorage.setItem(
          //         "userdetails",
          //         JSON.stringify(result.data)
          //       );

          //       localStorage.setItem(
          //         "permissions",
          //         JSON.stringify(result.data.permissions)
          //       );

          //       let user_permissions = result.data.permissions;
          //       let support_permissions = user_permissions["ticket"];
          //       delete user_permissions["ticket"];
          //       delete user_permissions["orderadhoc"];

          //       let has_support_permissions = Object.values(
          //         support_permissions
          //       ).some((perm) => perm == true);

          //       let has_other_permissions = [];

          //       Object.keys(user_permissions).forEach((key) => {
          //         has_other_permissions.push(
          //           Object.values(user_permissions[key])
          //         );
          //       });

          //       let CountModuleAllow = 0;
          //       if (
          //         CountModuleAllow <= 1 &&
          //         has_support_permissions &&
          //         !has_other_permissions
          //           .flat()
          //           .every((permission) => permission == true)
          //       ) {
          //         this.$router.push({ path: "/app/admin/support" });
          //       } else {
          //         this.$router.push({ path: "/app/admin/dashboard" });
          //       }
          //     })
          //     .catch((err) => {
          //       console.error(err);
          //       bus.$emit("showToastMessage", {
          //         message: "Something went wrong",
          //         color: "error",
          //       });
          //     });
          // }
        })
        .catch((err) => {
          if (err && err.data && err.data.non_field_errors) {
            bus.$emit("showToastMessage", {
              message: err.data.non_field_errors[0],
              color: "error",
            });
          }

          this.formError = err.data;
        });
    },
  },
  beforeDestroy() {
    this.checkIfLoggedIn();
  },
};
</script>

<style lang="scss">
// @import "~/assets/scss/variables.scss";
.container {
  width: 100% !important;
}
.login-main {
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .login-main {
    display: flex;
    align-items: flex-end;
    height: 100vh;
  }
}
.login-card {
  padding: 0px 48px;
  min-height: 80vh;
}

@media screen and (max-width: 1400px) {
  .login-card {
    padding: 0px 28px;
  }
}
@media screen and (max-width: 768px) {
  .login-card {
    // min-height: 50px;
    padding: 0px 8px 40px 8px;
    margin-bottom: 10%;
  }
}

input {
  background-color: white !important;
}

.custom-shadow-login {
  box-shadow: 0px 0px 47px -20px rgba(5, 5, 5, 0.5),
    0px -2px 12px -10px rgba(5, 5, 5, 0.3) !important;
}
.login-bg-image {
  position: fixed;
  height: 100vh !important;
  width: 100vw !important;
}
.login-title {
  text-align: center;
  text-transform: uppercase;
  font-weight: 100 !important;
  padding: 40px 0px;
  font-size: 38px;
  margin: 32px 0;
}

@media screen and (max-width: 1400px) {
  .login-title {
    font-size: 26px !important ;
    margin: 0px 0;
  }
}
@media screen and (max-width: 768px) {
  .login-title {
    font-size: 30px !important ;
    margin: 0px 0;
  }
}

.login-hr {
  width: 30%;
  margin-top: 24px;
  margin-bottom: 36px;
  background-color: red;
}

@media screen and (max-width: 1400px) {
  .login-hr {
    margin-top: 14px;
    margin-bottom: 6px;
  }
}
.login-logo-image {
  height: 120px;
}
.login-btn {
  text-transform: uppercase !important;
}
</style>
