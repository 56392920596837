<template>
  <BaseFormLayout :title="`${editMode ? 'Edit' : 'Add'} Route Quotation`" :nonFieldErrors="formErrors.non_field_errors">
    <template #actions>
      <BaseSecondaryButton v-if="!editMode" id="routeQuotationResetBtn" small class="mr-2 rounded-lg"
        @click="resetForm()">Reset</BaseSecondaryButton>
      <BaseButton id="routeQuotationSubmitBtn" small customClass="rounded-lg" @click="submitForm()"
        :disabled="!isValid || file == null">Submit</BaseButton>
    </template>

    <template #content>
      <v-row>
        <v-col cols="12">
          <v-card elevation="0" class="rounded-lg">
            <v-card-title class="">
              <h4 class="text-body-1 font-weight-bold black--text">
                Route Quotation Info
              </h4>
            </v-card-title>
            <v-card-subtitle>
              <h5 class="text-caption light_grey--text">
                All field(s) with (*) are mandatory
              </h5>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text>
              <v-form v-model="isValid" ref="routeQuotationForm" id="routeQuotationForm" name="routeQuotationForm">
                <v-row>
                  <v-col cols="3">
                    <BaseSelect id="route_type" name="route_type" v-model="routeQuotationDetails.route_type"
                      label="Select Route Type *" :itemsList="routeTypeOptions" itemText="name" itemValue="value"
                      :rules="[(val) => !!val || 'Route Type is required']" :error-messages="formErrors && formErrors.route_type
                        ? formErrors.route_type
                        : ''
                        " @change="
    formErrors && formErrors.route_type
      ? delete formErrors.route_type
      : ''
    " :isSearchRequired="false"></BaseSelect>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field ref="location_address" id="location_address" name="location_address"
                      placeholder="Location Address *" hide-details="auto"
                      v-model="routeQuotationDetails.location_address" :error-messages="formErrors && formErrors.location_address
                        ? formErrors.location_address
                        : ''
                        " @input="
    formErrors && formErrors.location_address
      ? delete formErrors.location_address
      : ''
    " :required="true" dense outlined class="rounded-lg" :rules="[
    (val) => !!val || 'Location Address is required',
  ]"></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field type="text" name="latitude" id="latitude" label="Latitude *" required dense
                      hide-details="auto" :disabled="true" :rules="[(v) => !!v || 'Latitude is Required']"
                      :error-messages="formErrors && formErrors.coordinates
                        ? formErrors.coordinates
                        : ''
                        " class="rounded-lg" v-model.number="coordinates.latitude" outlined></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field type="text" id="longitude" name="longitude" label="Longitude *" required dense
                      hide-details="auto" :disabled="true" :rules="[(v) => !!v || 'Longitude is Required']"
                      :error-messages="formErrors && formErrors.coordinates
                        ? formErrors.coordinates
                        : ''
                        " class="rounded-lg" v-model.number="coordinates.longitude" outlined></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <BaseSelect id="vehicle_types" name="vehicle_types" v-model="routeQuotationDetails.vehicle_types"
                      label="Vehicle Type *" :multiple="true" :itemsList="vehicleTypeList" itemText="name"
                      itemValue="value" :rules="[(val) => !!val || 'Vehicle Type is required']" :error-messages="formErrors && formErrors.vehicle_types
                        ? formErrors.vehicle_types
                        : ''
                        " @change="
    formErrors && formErrors.vehicle_types
      ? delete formErrors.vehicle_types
      : ''
    "></BaseSelect>
                  </v-col>
                  <v-col cols="3">
                    <BaseTimeInput id="start_time" name="start_time" v-model="routeQuotationDetails.start_time"
                      :required="true" label="Trip Start Time" :error-messages="formErrors && formErrors.start_time
                        ? formErrors.start_time
                        : ''
                        " @input="
    formErrors && formErrors.start_time
      ? delete formErrors.start_time
      : ''
    "></BaseTimeInput>
                  </v-col>
                  <v-col cols="3">
                    <BaseTextInput id="duration" name="duration" v-model="routeQuotationDetails.duration" :required="true"
                      label="Trip Duration(Min)" :error-messages="formErrors && formErrors.duration
                        ? formErrors.duration
                        : ''
                        " @input="
    formErrors && formErrors.duration
      ? delete formErrors.duration
      : ''
    "></BaseTextInput>
                  </v-col>
                  <v-col cols="3">
                    <BaseTextInput id="vehicle_fill_ratio" name="vehicle_fill_ratio" type="number"
                      v-model="routeQuotationDetails.vehicle_fill_ratio" :required="true" label="Vehicle Fill Ratio(%)"
                      :error-messages="formErrors && formErrors.vehicle_fill_ratio
                        ? formErrors.vehicle_fill_ratio
                        : ''
                        " @input="
    formErrors && formErrors.vehicle_fill_ratio
      ? delete formErrors.vehicle_fill_ratio
      : ''
    "></BaseTextInput>
                  </v-col>
                  <v-col cols="3">
                    <BaseTextInput id="per_stop_processing_time" name="per_stop_processing_time" type="number"
                      v-model="routeQuotationDetails.per_stop_processing_time" :required="true"
                      label="Stop Processing Time(Min)" :error-messages="formErrors && formErrors.per_stop_processing_time
                        ? formErrors.per_stop_processing_time
                        : ''
                        " @input="
    formErrors && formErrors.per_stop_processing_time
      ? delete formErrors.per_stop_processing_time
      : ''
    "></BaseTextInput>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" class="mt-5">
          <v-card elevation="0">
            <v-card-title>
              <h4 class="text-body-1 font-weight-bold black--text">
                Stops & Passengers
              </h4>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" class="mb-5">
                  <v-row class="d-flex justify-space-between align-center">
                    <v-col cols="4">
                      <v-file-input id="fileInputBulkUpload" v-model="file" hide-details :required="true" dense
                        label="Upload Sheet" prepend-inner-icon="mdi-attachment mdi-rotate-90" class="mr-4"
                        prepend-icon="" outlined @change="getFileData($event)"></v-file-input>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-end">
                      <BaseButton id="downloadSampleExcelBtn" small customClass="rounded-lg" @click="downloadExcel()">
                        Download Sample File</BaseButton>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <AgGridVue @grid-ready="onGridReady" :grid-options="gridOptions" :column-defs="headers"
                    :context="context" :row-data="dataObject" style="width: 100%; height: 450px" class="ag-theme-alpine">
                  </AgGridVue>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </BaseFormLayout>
</template>

<script>
import { routeTypeOptions } from "@/utils/optionsMapping.js";
import BaseFileUpload from "@/components/BaseComponents/BaseFileUpload.vue";
import { convertToBlobAndDownload } from "@/utils/functions.js";
import XLSX from "xlsx";
import { AgGridVue } from "ag-grid-vue";
import { bus } from "@/main";

export default {
  components: {
    BaseFileUpload,
    AgGridVue,
  },
  data() {
    return {
      routeTypeOptions,
      editMode: false,
      currentStep: 1,
      formErrors: {},
      isValid: true,
      routeQuotationDetails: {},
      formErrors: {},
      locationList: [],
      dataObject: [],
      gridApi: null,
      columnApi: null,
      gridOptions: {
        onGridSizeChanged: () => {
          this.gridOptions.api.sizeColumnsToFit();
        },
        headerHeight: 40,
        rowHeight: 40,
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },
      defaultColDef: {
        lockPosition: true,
      },
      headers: [
        {
          headerName: "Location Name",
          field: "location_name",
          editable: true,
        },
        {
          headerName: "Passengers Count",
          field: "employee_count",
          editable: true,
        },
      ],
      vehicleTypeList: [],
      file: null,
      coordinates: {},
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getVehicleTypesList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.vehicle_type.getVehicleTypeList(params).then((res) => {
        this.vehicleTypeList = res.data.map((obj) => {
          return {
            name: obj.type,
            value: obj.id,
          };
        });
      });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    headersChanged(value) {
      this.headerSelected = value;
    },
    getFileData(file) {
      if (file) {
        this.file = file;
        let reader = new FileReader();
        reader.onload = async () => {
          this.excelData = [];
          let fileData = reader.result;
          let wb = XLSX.read(fileData, {
            type: "binary",
            cellDates: true,
          });
          let rowData = XLSX.utils.sheet_to_row_object_array(
            wb.Sheets[wb.SheetNames[0]],
            {
              header: 0,
              defval: "",
              raw: false,
              cellNF: false,
            }
          );
          this.dataObject = rowData.map((row, index) => {
            let newObject = {};
            newObject.oldIndex = index;
            for (const [key, value] of Object.entries(row)) {
              let newKey = key
                .toString()
                .toLowerCase()
                .trim()
                .replace(/ /g, "_");
              newObject[newKey] = value.trim();
            }
            return newObject;
          });
        };

        reader.readAsBinaryString(file);
      } else {
        this.clearDialogData();
      }
    },
    resetRouteQuotation() {
      this.routeQuotationDetails = {};
      this.$refs.routeQuotationForm.reset();
      this.$refs.routeQuotationForm.resetValidation();
    },

    nextStep() {
      this.currentStep++;
    },

    submitForm() {
      let payload;
      payload = {
        ...this.routeQuotationDetails,
        location_coordinates: {
          latitude: this.coordinates.latitude,
          longitude: this.coordinates.longitude,
        },
        stop_passenger_info: this.dataObject,
      };
      console.log(payload);
      bus.$emit("showLoader", true);
      this.$api.route_quotation
        .createRouteQuotation(payload)
        .then(() => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Route Quotation Created Successfully!",
            color: "success",
          });
          this.$router.back();
        })
        .catch((err) => {
          this.formErrors = err.data;
        });
      this.nextStep();
    },

    setAutoComplete() {
      const interval = setInterval(async () => {
        if (
          this.$refs.location_address &&
          window.google &&
          window.google.maps
        ) {
          clearInterval(interval);

          let input = document.getElementById("location_address");

          this.autocomplete = new window.google.maps.places.Autocomplete(input);
          this.autocomplete.setComponentRestrictions({
            country: ["SA"],
          });

          this.autocomplete.addListener("place_changed", () => {
            let place = this.autocomplete.getPlace();
            let lat = place.geometry.location.lat().toFixed(6);
            let lon = place.geometry.location.lng().toFixed(6);
            setTimeout(() => {
              this.routeQuotationDetails.location_address =
                place.name + ", " + place.formatted_address;
              this.coordinates.latitude = lat;
              this.coordinates.longitude = lon;
            }, 100);
          });
        }
      }, 100);
    },

    getPickupDropStopsList(params) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.pickup_drop_stops.getPickupDropStopsList(params).then((res) => {
        this.locationList = res.data.map((obj) => {
          return {
            name: obj.location_name,
            value: obj.id,
          };
        });
      });
    },

    submitExcelFile() {
      console.log(this.dataObject);
    },
    downloadExcel() {
      console.log("method");
      this.$api.route_quotation
        .downloadExcelFile()
        .then((res) => {
          convertToBlobAndDownload(res.data, `Route Quotation Sample file.xlsx`);
          bus.$emit("showToastMessage", {
            message: "File downloaded Successfully! ",
            color: "success",
          });
        })
        .catch((err) => {
          console.error(err);
          bus.$emit("showToastMessage", {
            message: "Can't download File ",
            color: "error",
          });
        });
    }
  },
  mounted() {
    this.setAutoComplete();
    this.getPickupDropStopsList();
    this.getVehicleTypesList();
  },
};
</script>

<style lang="scss">
.square {
  height: 38.5px;
  width: 38.5px;
  background-color: white;
  border: 1.5px solid #8b8b8b;
  border-radius: 5px;
  font-size: 14px;
  color: #8b8b8b;
}

.squareTitle {
  height: 40px;
  width: 40px;
}

.v-stepper__step {
  padding: 2% 2% 2% 2%;
  margin-bottom: 1.5%;
}

// .v-stepper__step.v-stepper__step {
//   background-color: #d9f1ff;
// }
.v-stepper__step.v-stepper__step--active {
  background-color: white;
}
</style>