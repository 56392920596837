import CompanyIndex from "../pages/admin/company/index.vue";
import CompanyCreate from "../pages/admin/company/create/index.vue";
import CompanyDetail from "../pages/admin/company/detail/_id/index.vue";

const Company = [
  {
    path: "company",
    name: "Organization",
    component: CompanyIndex,
  },
  {
    path: "company/create",
    name: "company/create",
    component: CompanyCreate,
  },
  {
    path: "company/detail/:id",
    name: "company/detail/:id",
    component: CompanyDetail,
  },
];

export default Company;
