<template>
  <BaseListLayout
    ref="listLayout"
    title="Trips"
    :table-header="headers"
    :table-data="tripsList"
    :hasListActions="true"
    :total="totalItems"
    :context="context"
    @getList="getTripsList"
    name="tripSearch"
    id="tripSearch"
  >
    <!-- <template #listAction>
      <BaseButton
        id="tripCreateBtn"
        small
        customClass="font-weight-bold rounded-lg"
        @click="createTrip()"
        >Create Trip</BaseButton
      >
    </template> -->
    <template #leftFilterSlot>
      <BaseRefreshList
        id="refreshList"
        name="refreshList"
        :isIcon="true"
        newClass="ml-2 mr-2"
        @click="getTripsList({ offset: 0, limit: 10 })"
      ></BaseRefreshList>
      <BaseButton
        id="filterBtn"
        x-small
        fab
        depressed
        customClass="rounded-lg btn-small light_color_actions"
        icon="mdi-filter"
        iconColor="primary"
        iconClass="ma-1"
        iconSize="17px"
        @click="tripNewFilterDialog = true"
      ></BaseButton>
    </template>
    <template #dialogs>
      <TripsNewFilter
        v-model="tripNewFilterDialog"
        @tripNewFiltersChanged="$refs.listLayout.searchTriggered()"
      ></TripsNewFilter>

      <!-- <ProjectForm v-model="openCreateTrip" /> -->
    </template>
  </BaseListLayout>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout.vue";
// import TripActionsButton from "@/components/AgGridButtons/TripActionsButton.vue";
import TripForm from "@/components/ModuleBasedComponents/trips/TripForm.vue";
import TripsNewFilter from "@/components/ModuleBasedComponents/trips_new/TripsNewFilter.vue";
import { bus } from "@/main";
export default {
  components: {
    BaseListLayout,
    // TripActionsButton,
    TripForm,
    TripsNewFilter,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      openCreateTrip: false,
      tripNewFilterDialog: false,
      headers: [
        {
          headerName: "Trip ID",
          field: "reference_number",
        },
        {
          headerName: "Route Name",
          field: "route",
        },

        // {
        //   headerName: "Total Passengers",
        //   field: "total_passengers",
        // },
        // {
        //   headerName: "Total Stops",
        //   field: "total_stops",
        // },
        {
          headerName: "Driver",
          field: "driver",
        },
        {
          headerName: "Vehicle",
          field: "vehicle",
        },
        {
          headerName: "Organization",
          field: "company",
        },
        {
          headerName: "Trip Date",
          field: "trip_date",
        },
        {
          headerName: "Total Stops",
          field: "total_stops",
        },
        // {
        //   headerName: "Actions",
        //   field: "actions",
        //   cellRenderer: "TripActionsButton",
        // },
      ],
      tripsList: [],
      filters: {},
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getTripsList(params = {}) {
      let filters = localStorage.getItem("tripNewFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if (filters.project_id && filters.project_id.length > 0) {
        filters.project_id = filters.project_id.join(",");
      }
      if (filters.status && filters.status.length > 0) {
        filters.status = filters.status.join(",");
      }
      this.filters = filters;
      params = {
        ...params,
        ...this.filters,
      };
      bus.$emit("showLoader", true);
      this.$api.trip_redundant
        .getTripList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.tripsList = res?.data;
          this.totalItems = res?.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    headersChanged(value) {
      this.headerSelected = value;
    },

    createTrip() {
      this.$router.push("/app/admin/trips/create");
    },

    editTrip(id) {
      this.$router.push(`/app/admin/trips/edit/${id}`);
    },
  },
};
</script>

<style>
</style>