var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseFormLayout',{attrs:{"title":`${_vm.editMode ? 'Edit' : 'Add'} Route`,"nonFieldErrors":_vm.formErrors.non_field_errors},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(!_vm.editMode)?_c('BaseSecondaryButton',{staticClass:"mr-2 rounded-lg",attrs:{"id":"routesResetBtn","small":""},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v("Reset")]):_vm._e(),_c('BaseButton',{attrs:{"id":"routesSubmitBtn","small":"","customClass":"rounded-lg","disabled":!_vm.isValid},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("Submit")])]},proxy:true},{key:"content",fn:function(){return [_c('v-form',{ref:"routesForm",attrs:{"id":"routesForm","name":"routesForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"mx-2"},[_c('v-col',{staticClass:"white rounded-lg",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"pa-2"},[_c('h4',{staticClass:"text-body-1 font-weight-bold black--text"},[_vm._v(" Basic Info. ")])]),_c('v-divider'),_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"cols":"3"}},[_c('BaseTextInput',{attrs:{"id":"route_id","name":"route_id","label":"Route ID","required":true,"error-messages":_vm.formErrors && _vm.formErrors.route_id
                        ? _vm.formErrors.route_id
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.route_id
                        ? delete _vm.formErrors.route_id
                        : ''}},model:{value:(_vm.routeFormDetails.route_id),callback:function ($$v) {_vm.$set(_vm.routeFormDetails, "route_id", $$v)},expression:"routeFormDetails.route_id"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseTextInput',{attrs:{"id":"route_name","name":"route_name","label":"Route Name","required":true,"error-messages":_vm.formErrors && _vm.formErrors.route_name
                        ? _vm.formErrors.route_name
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.route_name
                        ? delete _vm.formErrors.route_name
                        : ''}},model:{value:(_vm.routeFormDetails.route_name),callback:function ($$v) {_vm.$set(_vm.routeFormDetails, "route_name", $$v)},expression:"routeFormDetails.route_name"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseSelect',{attrs:{"id":"route_type","name":"route_type","required":true,"label":"Select Route Type *","itemsList":_vm.routeTypeList,"isClearable":false,"item-text":"name","item-value":"value","rules":[(val) => !!val || 'Route Type is required'],"error-messages":_vm.formErrors && _vm.formErrors.route_type
                        ? _vm.formErrors.route_type
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.route_type
                        ? delete _vm.formErrors.route_type
                        : ''}},model:{value:(_vm.routeFormDetails.route_type),callback:function ($$v) {_vm.$set(_vm.routeFormDetails, "route_type", $$v)},expression:"routeFormDetails.route_type"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseSelect',{attrs:{"id":"project","name":"project","required":true,"label":"Select Project *","itemsList":_vm.projectList,"isClearable":false,"item-text":"name","item-value":"value","rules":[(val) => !!val || 'Project is required'],"error-messages":_vm.formErrors && _vm.formErrors.project
                        ? _vm.formErrors.project
                        : ''},on:{"change":function($event){_vm.formErrors && _vm.formErrors.project
                        ? delete _vm.formErrors.project
                        : '',
                        _vm.getCompanyList({ project_id: _vm.routeFormDetails.project })}},model:{value:(_vm.routeFormDetails.project),callback:function ($$v) {_vm.$set(_vm.routeFormDetails, "project", $$v)},expression:"routeFormDetails.project"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseSelect',{attrs:{"id":"company","name":"company","required":true,"label":"Select Organization *","itemsList":_vm.companyList,"isClearable":false,"item-text":"name","item-value":"value","rules":[(val) => !!val || 'Organization is required'],"error-messages":_vm.formErrors && _vm.formErrors.company
                        ? _vm.formErrors.company
                        : ''},on:{"change":function($event){_vm.formErrors && _vm.formErrors.company
                        ? delete _vm.formErrors.company
                        : '',
                        _vm.getCompanyAddressesList({
                          company_id: _vm.routeFormDetails.company,
                        }),
                        _vm.getCompanyShiftList({
                          company_id: _vm.routeFormDetails.company,
                        }),
                        _vm.sendFiltersToEmployees('company_id', $event)}},model:{value:(_vm.routeFormDetails.company),callback:function ($$v) {_vm.$set(_vm.routeFormDetails, "company", $$v)},expression:"routeFormDetails.company"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseSelect',{attrs:{"id":"company_address","name":"company_address","required":true,"label":"Select Organization Address *","itemsList":_vm.companyAddressList,"isClearable":false,"item-text":"location_name","item-value":"id","rules":[(val) => !!val || 'Organization Address is required'],"error-messages":_vm.formErrors && _vm.formErrors.company_address
                        ? _vm.formErrors.company_address
                        : ''},on:{"change":function($event){_vm.formErrors && _vm.formErrors.company_address
                        ? delete _vm.formErrors.company_address
                        : ''}},model:{value:(_vm.routeFormDetails.company_address),callback:function ($$v) {_vm.$set(_vm.routeFormDetails, "company_address", $$v)},expression:"routeFormDetails.company_address"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseSelect',{attrs:{"id":"company_shift","name":"company_shift","required":true,"label":"Select Shift Time *","itemsList":_vm.shiftList,"isClearable":false,"item-text":"name","item-value":"value","rules":[(val) => !!val || 'Shift Time is required'],"error-messages":_vm.formErrors && _vm.formErrors.company_shift
                        ? _vm.formErrors.company_shift
                        : ''},on:{"change":function($event){_vm.formErrors && _vm.formErrors.company_shift
                        ? delete _vm.formErrors.company_shift
                        : ''}},model:{value:(_vm.routeFormDetails.company_shift),callback:function ($$v) {_vm.$set(_vm.routeFormDetails, "company_shift", $$v)},expression:"routeFormDetails.company_shift"}})],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"ma-0 pa-0 d-flex justify-space-between"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mt-10 white mx-2 rounded-lg"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"pa-2"},[_c('h4',{staticClass:"text-body-1 font-weight-bold black--text"},[_vm._v(" Stops & Passengers Details ")])]),_c('v-divider'),_c('v-card-text',[_c('v-row',{staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"2"}},[_c('h4',{staticClass:"text-uppercase light_grey--text font-weight-bold text-caption"},[_vm._v(" stops ")])]),_c('v-col',{staticClass:"d-flex justify-end align-center ml-4",attrs:{"cols":"2"}},[_c('h4',{staticClass:"text-uppercase light_grey--text font-weight-bold text-caption"},[_vm._v(" no. of passengers ")])])],1),_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-timeline',{attrs:{"dense":"","align-top":""}},_vm._l((_vm.route_stops),function(route_stop,index){return _c('div',{key:index},[_c('v-timeline-item',{attrs:{"small":"","icon":"mdi-check","color":"primary"}},[_c('v-row',{},[_c('v-col',{attrs:{"cols":"3"}},[_c('BaseSelect',{attrs:{"id":"location_id","name":"location_id","required":true,"label":"Pickup Location *","itemsList":_vm.locationList,"itemText":"location_name","itemValue":"id","item-disabled":_vm.isItemDisabled,"isClearable":false,"rules":[
                                    (val) =>
                                      !!val || 'Pickup Location is required',
                                  ],"error-messages":_vm.formErrors && _vm.formErrors.location_id
                                      ? _vm.formErrors.location_id
                                      : ''},on:{"change":function($event){_vm.formErrors && _vm.formErrors.location_id
                                      ? delete _vm.formErrors.location_id
                                      : '',
                                      _vm.sendFiltersToEmployees(
                                        _vm.routeFormDetails.route_type == 'drop'
                                          ? 'drop_location'
                                          : 'pickup_location',
                                        $event
                                      )}},model:{value:(route_stop.location_id),callback:function ($$v) {_vm.$set(route_stop, "location_id", $$v)},expression:"route_stop.location_id"}}),_c('div',{staticClass:"d-flex justify-end mt-2"},[_c('BaseSecondaryButton',{attrs:{"id":"addPickupRouteBtn","icon":"mdi-plus","customClass":"routeButtons mr-2","iconColor":"primary","iconClass":"mx-1","x-small":"","outlined":""},on:{"click":function($event){return _vm.addPickUpRoute()}}}),_c('BaseSecondaryButton',{attrs:{"disabled":_vm.route_stops.length == 1,"id":"removePickupRouteBtn","icon":"mdi-minus","customClass":"routeButtons red--text","iconColor":"red","iconClass":"mx-1","x-small":"","outlined":""},on:{"click":function($event){return _vm.removePickUpRoute(index)}}})],1)],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"2"}},[_c('BaseButton',{attrs:{"disabled":route_stop.total_passengers > 0 ||
                                    route_stop.location_id == null,"id":"passengersBtn","icon":"mdi-account","iconColor":"black","hint":"Please select stop to add passenger(s)","outlined":"","customClass":route_stop.total_passengers > 0
                                      ? 'disabledPassengersClass light_button_grey rounded-lg'
                                      : 'passengersClass light_button_grey rounded-lg'},on:{"click":function($event){return _vm.addPassengers(index)}}},[_c('span',{staticClass:"black--text font-weight-bold text-body-2 text-capitalize"},[_vm._v(" ("+_vm._s(route_stop.total_passengers && route_stop.total_passengers > 0 ? route_stop.total_passengers : 0)+")    Passenger(s)")])]),(route_stop.total_passengers > 0)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.passengersData(_vm.passengersList)}}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-eye")])],1):_vm._e()],1)],1)],1)],1)}),0)],1)],1)],1)],1)],1)],1)],1)],1)],1)]},proxy:true},{key:"dialogs",fn:function(){return [_c('AddEmployeeToRoutes',{ref:"addEmployeesToRoutes",on:{"passengersList":_vm.passengersList},model:{value:(_vm.openAddEmployees),callback:function ($$v) {_vm.openAddEmployees=$$v},expression:"openAddEmployees"}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }