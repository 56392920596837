<template>
  <div class="custom-list">
    <v-row no-gutters class="ma-0">
      <v-col cols="12" class="text-left pa-0">
        <v-card elevation="0" class="rounded-lg w-100 pa-4 light_background">
          <v-row no-gutters>
            <v-col cols="6" class="d-flex">
              <BaseActionsButton
                iconName="mdi-arrow-left-bold"
                iconColor="primary"
                customClass="mr-2"
                @click="$router.back()"
                tooltipMessage="Back"
              ></BaseActionsButton>
              <h3 class="text-uppercase text-h6 font-weight-bold">
                Route Plan -
                {{
                  routePlanDetails && routePlanDetails.plan_name
                    ? routePlanDetails.plan_name
                    : ""
                }}
              </h3>
            </v-col>
            <v-col cols="6" class="d-flex justify-end align-center">
              <BaseButton
                id="createRoutesBtn"
                customClass="rounded-lg font-weight-bold"
                small
                :disabled="routePlanDetails.action_taken"
                @click="submitForm()"
                >Create Route(s)</BaseButton
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="3" class="mt-4 px-4">
        <v-card elevation="0" class="pa-0 rounded-lg">
          <v-card-title class="">
            <h4 class="text-body-1 font-weight-bold black--text">Plan Info.</h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="routePlanForm">
              <v-row>
                <v-col cols="12">
                  <span>
                    <h4 class="text-caption">Plan Name</h4>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ routePlanDetails.plan_name }}
                    </h5>
                  </span>
                  <!-- <BaseTextInput
                    :disabled="true"
                    id="plan_name"
                    name="plan_name"
                    v-model="routePlanDetails.plan_name"
                    label="Plan Name"
                  ></BaseTextInput> -->
                </v-col>

                <v-col cols="12">
                  <span>
                    <h4 class="text-caption">Organization</h4>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ routePlanDetails.company }}
                    </h5>
                  </span>
                  <!-- <BaseTextInput
                    :disabled="true"
                    id="company"
                    name="company"
                    v-model="routePlanDetails.company"
                    label="Organization"
                  ></BaseTextInput> -->
                  <!-- <BaseSelect
                    :disabled="true"
                    id="company"
                    name="company"
                    v-model="routePlanDetails.company"
                    :itemsList="companyList"
                    itemText="name"
                    itemValue="value"
                    label="Select Organization *"
                    :rules="[(val) => !!val || 'Organization is required']"
                    @change="
                      getCompanyAddressesList({
                        company_id: routePlanDetails.company,
                      }),
                        getCompanyShiftList({
                          company_id: routePlanDetails.company,
                        })
                    "
                  ></BaseSelect> -->
                </v-col>
                <v-col cols="12">
                  <span>
                    <h4 class="text-caption">Organization Address</h4>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ routePlanDetails.company_address }}
                    </h5>
                  </span>
                  <!-- <BaseTextInput
                    :disabled="true"
                    id="company_address"
                    name="company_address"
                    v-model="routePlanDetails.company_address"
                    label="Organization Address"
                  ></BaseTextInput> -->
                  <!-- <BaseSelect
                    :disabled="true"
                    id="company_address"
                    name="company_address"
                    v-model="routePlanDetails.company_address"
                    :itemsList="companyAddressesList"
                    itemText="name"
                    itemValue="value"
                    label="Select Organization Address *"
                  ></BaseSelect> -->
                </v-col>
                <v-col cols="12">
                  <span>
                    <h4 class="text-caption">Organization Shift</h4>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ routePlanDetails.shift_timings }}
                    </h5>
                  </span>
                  <!-- <BaseTextInput
                    :disabled="true"
                    id="company_shift"
                    name="company_shift"
                    v-model="routePlanDetails.company_shift"
                    label="Organization Shift"
                  ></BaseTextInput> -->
                  <!-- <BaseSelect
                    :disabled="true"
                    id="company_shift"
                    name="company_shift"
                    v-model="routePlanDetails.company_shift"
                    :itemsList="companyShiftList"
                    itemText="name"
                    itemValue="value"
                    label="Select Organization Shift *"
                  ></BaseSelect> -->
                </v-col>
                <v-col cols="12">
                  <span>
                    <h4 class="text-caption">Route Type</h4>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ routePlanDetails.route_type }}
                    </h5>
                  </span>
                  <!-- <BaseTextInput
                    :disabled="true"
                    id="route_type"
                    name="route_type"
                    v-model="routePlanDetails.route_type"
                    label="Route Type"
                  ></BaseTextInput> -->
                  <!-- <BaseSelect
                    :disabled="true"
                    id="route_type"
                    name="route_type"
                    v-model="routePlanDetails.route_type"
                    :itemsList="routeTypeOptions"
                    itemText="name"
                    itemValue="value"
                    label="Route Type *"
                  ></BaseSelect> -->
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="9" class="mt-4 px-4">
        <v-expansion-panels
          v-for="(routePlan, routePlanIndex) in routePlanList"
          :key="routePlanIndex"
          flat
          class="mb-3"
        >
          <RoutePlanExpansionPanel
            v-model="routePlanList[routePlanIndex]"
            :routePlanIndex="routePlanIndex"
            :routeErrors="routeErrors[routePlanIndex]"
            :selectedRoutes="selectedRoutes"
            @routeSelected="routeSelected($event, routePlan.id)"
            @viewMap="routeMapped($event)"
          ></RoutePlanExpansionPanel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-dialog v-model="openShowOnMapDialog" persistent width="70%">
      <v-card>
        <v-card-title class="py-2 heading">
          <span> Planned Route </span>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            fab
            small
            color="heading"
            @click="openShowOnMapDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-3">
          <GoogleMap
            mapWidth="100%"
            mapHeight="calc(100vh - 380px)"
            ref="routeMap"
          ></GoogleMap>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { bus } from "@/main";
import GoogleMap from "@/components/BaseComponents/GoogleMap.vue";
import RoutePlanExpansionPanel from "@/components/ModuleBasedComponents/route_planning/RoutePlanExpansionPanel.vue";
export default {
  components: {
    GoogleMap,
    RoutePlanExpansionPanel,
  },
  data() {
    return {
      routePlanDetails: {},
      companyShiftList: [],
      companyList: [],
      openShowOnMapDialog: false,
      companyAddressesList: [],
      routeTypeOptions: [
        {
          name: "PickUp",
          value: "pick_up",
        },
        {
          name: "Drop",
          value: "drop",
        },
      ],
      routePlanList: [],
      routeErrors: [],
      selectedRoutes: [],
    };
  },
  methods: {
    routeSelected(e, id) {
      if (e) {
        this.selectedRoutes.push(id);
      } else {
        this.selectedRoutes.splice(
          this.selectedRoutes.indexOf(this.selectedRoutes.find((v) => v == id)),
          1
        );
      }
    },
    routeMapped(e) {
      this.openShowOnMapDialog=true;
      let timer = setTimeout(() => {
        if (this.$refs && this.$refs.routeMap) {
          this.$refs.routeMap.clearMarker();
          this.$refs.routeMap.clearGeoJson();

          setTimeout(() => {
            if (e) {
              this.$refs.routeMap.setPlannedRoute(
                e,
                "route_stops"
              );
            }
          }, 100);
        }
      }, 400);
    },
    getRoutePlanObject(id) {
      bus.$emit("showLoader", true);
      this.$api.route_planning
        .getRoutePlanObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.routePlanDetails = res.data;
          this.routePlanList = res.data.planned_routes;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    getCompanyList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      bus.$emit("showLoader", true);
      this.$api.company.getCompanyList(params).then((res) => {
        bus.$emit("showLoader", false);
        this.companyList = res.data.map((obj) => {
          return {
            name: obj.name,
            value: obj.id,
          };
        });
      });
    },
    getCompanyAddressesList(params = {}) {
      params = {
        ...params,
        limit: "all",
        company_id: this.routePlanDetails.company,
      };
      bus.$emit("showLoader", true);
      this.$api.company_addresses
        .getCompanyAddressesList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.companyAddressesList = res.data.map((obj) => {
            return {
              name: obj.location_name,
              value: obj.id,
            };
          });
        });
    },
    getCompanyShiftList(params = {}) {
      params = {
        ...params,
        limit: "all",
        company_id: this.routePlanDetails.company,
      };
      bus.$emit("showLoader", true);

      this.$api.company_shifts.getCompanyShiftList(params).then((res) => {
        bus.$emit("showLoader", false);
        this.companyShiftList = res.data.map((obj) => {
          return {
            name: obj.shift_name,
            value: obj.id,
          };
        });
      });
    },
    submitForm() {
      let data = { id: this.$route.params.id, data: [...this.selectedRoutes] };
      this.$api.route_planning
        .createRoutes(data)
        .then((res) => {
          bus.$emit("showToastMessage", {
            message: "Route(s) Created!",
            color: "success",
          });
          this.$router.push("/app/admin/routes");
        })
        .catch((err) => {
          console.err(err);
        });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.getRoutePlanObject(this.$route.params.id);
      this.getCompanyAddressesList();
      this.getCompanyList();
      this.getCompanyShiftList();
    }
  },
};
</script>

<style>
</style>