<template>
  <v-dialog
    v-model="routePlanningFilterDialog"
    @submit="applyFilters()"
    @reset="resetFilters()"
    width="30%"
    ><v-card>
      <v-card-title>
        <v-row class="d-flex justify-space-between">
          <v-col cols="6">
            <h3 class="text-body-1 primary--text font-weight-bold">
              Route Planning Filter
            </h3>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-icon @click="routePlanningFilterDialog = false" color="primary"
              >mdi-close</v-icon
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4">
        <v-form ref="routePlanningFilterForm">
          <v-row no-gutters>
            <v-col cols="12" md="6" lg="6" xl="6" class="">
              <BaseSelect
                dense
                hide-details
                outlined
                clearable
                label="Select Project"
                :itemsList="projectList"
                itemText="name"
                itemValue="value"
                :multiple="true"
                v-model="routePlanningFilter.project_id"
                class="pl-1"
                :menu-props="{ offsetY: true }"
                @change="syncRoutePlanningFilter('project_id', $event)"
              ></BaseSelect>
            </v-col>

            <v-col cols="12" md="6" lg="6" xl="6" class="pl-2">
              <BaseSelect
                id="companyFilter"
                dense
                hide-details
                outlined
                clearable
                label="Select Organization"
                :itemsList="companyList"
                itemText="name"
                itemValue="value"
                :multiple="true"
                v-model="routePlanningFilter.company_id"
                :menu-props="{ offsetY: true }"
                @change="
                  syncRoutePlanningFilter('company_id', $event),
                    getCompanyShiftList({
                      company_id: routePlanningFilter.company_id,
                    })
                "
              ></BaseSelect>
            </v-col>

            <v-col cols="12" md="6" lg="6" xl="6" class="pt-2 pl-1">
              <BaseSelect
                id="companyShiftFilter"
                dense
                hide-details
                outlined
                clearable
                label="Select Organization Shift"
                :itemsList="companyShiftList"
                itemText="name"
                itemValue="value"
                :multiple="true"
                v-model="routePlanningFilter.company_shift"
                :menu-props="{ offsetY: true }"
                @change="syncRoutePlanningFilter('company_shift', $event)"
              ></BaseSelect>
            </v-col>

            <v-col cols="12" md="6" lg="6" xl="6" class="pt-2 pl-2">
              <BaseSelect
                v-model="routePlanningFilter.route_type"
                :itemsList="routeTypeOptions"
                itemText="name"
                itemValue="value"
                id="routeTypeFilter"
                name="routeTypeFilter"
                label="Route Type"
                @change="syncRoutePlanningFilter('route_type', $event)"
                :isClearable="false"
                :isSearchRequired="false"
              ></BaseSelect>
            </v-col>
            <v-col cols="12" md="12" lg="12" xl="12" class="pt-2 pl-1">
              <BaseSelect
                v-model="routePlanningFilter.status"
                :itemsList="routePlanningOptions"
                itemText="name"
                itemValue="value"
                id="statusFilter"
                name="statusFilter"
                label="Status"
                @change="syncRoutePlanningFilter('status', $event)"
                :isClearable="false"
                :isSearchRequired="false"
              ></BaseSelect>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-end">
        <BaseSecondaryButton
          small
          customClass="rounded-lg"
          id="resetRoutePlanningFilter"
          @click="resetFilters()"
          >Reset</BaseSecondaryButton
        >
        <BaseButton
          small
          customClass="rounded-lg"
          id="applyRoutePlanningFilter"
          @click="applyFilters()"
          >Apply</BaseButton
        >
      </v-card-actions>
    </v-card></v-dialog
  >
</template>

<script>
import {
  routeTypeOptions,
  routePlanningOptions,
} from "@/utils/optionsMapping.js";
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      routeTypeOptions,
      routePlanningOptions,
      projectList: [],
      companyList: [],
      companyShiftList: [],
      routePlanningFilter: {},
    };
  },
  watch: {
    routePlanningFilterDialog(value) {
      if (value) {
        this.getProjectsList();
        this.getCompanyList();

        let filters = localStorage.getItem("routePlanningFilters");
        if (!filters) {
          filters = {};
        }
        if (typeof filters == typeof "string") {
          filters = JSON.parse(filters);
        }
        this.routePlanningFilter = filters;
        if (this.routePlanningFilter.company_id) {
          this.getCompanyShiftList({
            company_id: this.routePlanningFilter.company_id,
          });
        }
      }
    },
  },
  computed: {
    routePlanningFilterDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getProjectsList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.projects.getProjectList(params).then((res) => {
        this.projectList = res.data.map((obj) => {
          return {
            name: obj.project_name,
            value: obj.project_id,
          };
        });
      });
    },
    getCompanyShiftList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.company_shifts.getCompanyShiftList(params).then((res) => {
        this.companyShiftList = res.data.map((obj) => {
          return {
            name: obj.shift_name,
            value: obj.shift_id,
          };
        });
      });
    },
    getCompanyList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.company.getCompanyList(params).then((res) => {
        this.companyList = res.data.map((obj) => {
          return {
            name: obj.name,
            value: obj.company_id,
          };
        });
      });
    },
    syncRoutePlanningFilter(key, value) {
      let filters = localStorage.getItem("routePlanningFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if (value !== null && value.length > 0) {
        filters[key] = value;
      } else {
        delete filters[key];
      }
      this.routeFilter = filters;
      localStorage.setItem("routePlanningFilters", JSON.stringify(filters));
    },
    applyFilters(reload) {
      this.$emit("routePlanningFiltersChanged");
      this.routePlanningFilterDialog = false;
    },
    resetFilters(reload) {
      this.routeFilter = {};
      localStorage.removeItem("routePlanningFilters");
      this.$emit("routePlanningFiltersChanged");
      if (this.$refs.routePlanningFilterForm) {
        this.$refs.routePlanningFilterForm.reset();
      }
    },
  },
};
</script>

<style>
</style>