<template>
  <v-expansion-panel class="border-expansion rounded-lg white">
    <v-expansion-panel-header
      class="white--text content_background"
      expand-icon="mdi-menu-down"
    >
      <v-row class="ma-0">
        <v-col cols="10 " class="pa-0 d-flex justify-start align-center">
          <v-row no-gutters class="ma-0 pa-0">
            <!-- <div
                class="pa-0 ma-0 d-flex justify-center align-center"
                v-if="checkboxRequired"
              >
                <v-checkbox
                  class="checkboxClass shrink ma-0 align-center pa-0"
                  :input-value="isSelectedRoute(routePlanObject.id)"
                  v-if="!routePlanObject.action_taken"
                  hide-details
                  @click.stop.prevent
                  @change="$emit('routeSelected', $event, routePlanObject.id)"
                ></v-checkbox>
              </div> -->
            <v-col cols="2" class="d-flex justify-start align-center">
              <h4
                class="black--text font-weight-medium"
                v-if="routePlanObject && routePlanObject.route_name"
              >
                {{
                  routePlanObject && routePlanObject.route_name
                    ? routePlanObject.route_name
                    : ""
                }}
              </h4>
              <h4 v-else class="black--text font-weight-medium">
                {{
                  routePlanObject && routePlanObject.route_id
                    ? routePlanObject.route_id
                    : ""
                }}
              </h4>
            </v-col>
            <v-col cols="2" class="d-flex justify-center align-center">
              <h4 class="d-flex">
                <span class="light_grey--text font-weight-medium"
                  >Stops:</span
                >
                &nbsp;&nbsp;
                <span class="black--text font-weight-medium">
                  {{
                    routePlanObject && routePlanObject.total_stops
                      ? routePlanObject.total_stops
                      : ""
                  }}
                </span>
              </h4>
            </v-col>
            <v-col cols="2" class="d-flex justify-center align-center">
              <h4 class="d-flex">
                <span class="light_grey--text font-weight-medium"
                  >Passengers:</span
                >
                &nbsp;&nbsp;
                <span class="black--text font-weight-medium">
                  {{
                    routePlanObject && routePlanObject.total_employees
                      ? routePlanObject.total_employees
                      : ""
                  }}
                </span>
              </h4>
            </v-col>
            <v-col cols="3" class="d-flex justify-center align-center">
              <h4 class="d-flex">
                <span class="light_grey--text font-weight-medium"
                  >Distance:</span
                >
                &nbsp;&nbsp;
                <span class="black--text font-weight-medium">
                  {{
                    routePlanObject && routePlanObject.distance
                      ? routePlanObject.distance
                      : ""
                  }}
                  &nbsp;&nbsp;
                  KM
                </span>
              </h4>
            </v-col>
            <v-col cols="3" class="d-flex justify-center align-center">
              <h4 class="d-flex">
                <span class="light_grey--text font-weight-medium"
                  >Total Time:</span
                >
                &nbsp;&nbsp;
                <span class="black--text font-weight-medium">
                  {{
                    routePlanObject && routePlanObject.total_time
                      ? routePlanObject.total_time
                      : ""
                  }}
                </span>
              </h4>
            </v-col>
            
          </v-row>
        </v-col>
        <v-col cols="2" class="pa-0 d-flex justify-end align-center">
          <BaseActionsButton
            iconName="mdi-map"
            iconColor="primary"
            customClass="mr-2"
            @click.stop="$emit('viewMap', routePlanObject.planned_route )"
            tooltipMessage="View On Map"
          ></BaseActionsButton>
          <!-- <BaseActionsButton
              iconName="mdi-close"
              iconColor="primary"
              customClass="mr-2"
              @click="$emit('removeRoutePlan', routePlanIndex)"
              tooltipMessage="Remove"
            ></BaseActionsButton> -->
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-icon color="primary"> mdi-chevron-down </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-divider></v-divider>
    <v-expansion-panel-content
      class="expansion-container-margin rounded rounded-t-0 pa-0"
      color="white"
    >
      <v-row class="ma-0 pa-2">
        <v-col
          cols="12"
          class="pa-2"
          v-if="routeErrors && routeErrors.non_field_errors"
        >
          <v-alert dense type="error" class="ma-0">
            <v-list
              class="pa-0"
              dense
              style="background: inherit !important"
              v-for="(error, i) in routeErrors.non_field_errors"
              :key="i"
            >
              <v-list-item dense style="min-height: 20px !important">
                <span>{{ i }} .</span><span>{{ error }}</span>
                <v-spacer></v-spacer>
                <v-btn
                  color="white"
                  icon
                  @click="
                    $emit('removeError', routePlanIndex, 'non_field_errors')
                  "
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-timeline dense align-top>
            <div
              v-for="(route_stop, index) in routePlanObject.route_stops"
              :key="index"
            >
              <v-timeline-item
                small
                icon="mdi-check"
                color="primary"
                v-if="routePlanObject && routePlanObject.route_name"
              >
                <v-row class="">
                  <v-col cols="6">
                    <BaseTextInput
                      id="location_name"
                      name="location_name"
                      v-model="route_stop.location_name"
                      :label="getLabel(route_stop.type)"
                    ></BaseTextInput>
                    <!-- <BaseSelect
                        id="location_id"
                        name="location_id"
                        v-model="route_stop.location_id"
                        :required="true"
                        label="Pickup Location *"
                        :itemsList="locationList"
                        itemText="location_name"
                        itemValue="id"
                        :isClearable="false"
                        :rules="[(val) => !!val || 'Pickup Location is required']"
                      ></BaseSelect> -->

                    <!-- sendFiltersToEmployees(
                              routeFormDetails.route_type == 'drop'
                                ? 'drop_location'
                                : 'pickup_location',
                              $event
                            ) -->
                    <!-- <div class="d-flex justify-end mt-2">
                        <BaseSecondaryButton
                          id="addPickupRouteBtn"
                          icon="mdi-plus"
                          customClass="routeButtons mr-2"
                          iconColor="primary"
                          iconClass="mx-1"
                          x-small
                          outlined
                          @click="addPickUpRoute()"
                        ></BaseSecondaryButton>
                        <BaseSecondaryButton
                          :disabled="routePlanObject.route_stops.length == 1"
                          id="removePickupRouteBtn"
                          icon="mdi-minus"
                          customClass="routeButtons red--text"
                          iconColor="red"
                          iconClass="mx-1"
                          x-small
                          outlined
                          @click="removePickUpRoute(index)"
                        ></BaseSecondaryButton>
                      </div> -->
                  </v-col>

                  <!-- <v-col cols="2">
                      <BaseTimeInput
                        id="time"
                        name="time"
                        v-model="route_stop.time"
                        label="Time"
                        :required="true"
                      ></BaseTimeInput>
                    </v-col> -->

                  <v-col cols="3">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :customClass="
                            route_stop.total_employees > 0
                              ? 'disabledPassengersClass light_button_grey rounded-lg'
                              : 'passengersClass light_button_grey rounded-lg'
                          "
                        >
                          <span
                            class="black--text font-weight-bold text-body-2 text-capitalize"
                          >
                            {{ route_stop.total_employees || 0 }}
                            {{
                              route_stop.total_employees === 1
                                ? "Passenger"
                                : "Passengers"
                            }}</span
                          >
                        </v-btn>
                      </template>
                      <v-card width="500">
                        <v-card-title>
                          Passengers
                        </v-card-title>
                        <v-card-text>
                          <v-chip class="px-2 py-0 ma-1 text-caption" v-for="(header, i) in route_stop.employee_list"
                          :key="i">
                            {{ header }}
                          </v-chip>
                        </v-card-text>
                      </v-card>
                      <span>
                        
                      </span>
                    </v-tooltip>
                    <!-- <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" :disabled="route_stop.total_employees == 0">
                          <span
                            class="black--text font-weight-bold text-body-2 text-capitalize"
                          >
                            {{ route_stop.total_employees || 0 }}
                            {{
                              route_stop.total_employees === 1
                                ? "Passenger"
                                : "Passengers"
                            }}</span
                          >
                        </v-btn>
                      </template>
                      <span>{{ route_stop.employee_list.join(",") }}</span>
                    </v-tooltip> -->
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item v-else small icon="mdi-check" color="primary">
                <v-row no-gutters class="d-flex justify-center">
                  <v-col cols="6" class="d-flex justify-start pa-0 ma-0">
                    <h4 class="text-caption font-weight-bold">
                      {{ route_stop.address }}
                    </h4>
                  </v-col>
                  <v-col cols="6" class="d-flex justify-start px-10">
                    <h4 class="text-caption font-weight-bold">
                      {{ route_stop.employee_count }} Passengers(s)
                    </h4>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </div>
          </v-timeline>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import AddEmployeeToRoutes from "../employees/AddEmployeeToRoutes.vue";
export default {
  props: {
    value: {
      required: true,
    },
    routePlanIndex: {
      required: true,
    },
    routeErrors: {
      required: true,
    },
    selectedRoutes: {
      required: true,
    },
    checkboxRequired: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    AddEmployeeToRoutes,
  },
  data() {
    return {
      locationList: [],
      passengersList: [],
      filters: [],
      openAddEmployees: false,
    };
  },
  computed: {
    routePlanObject: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    isSelectedRoute(routeId) {
      return this.selectedRoutes.indexOf(routeId) > -1;
    },
    addPassengers(index) {
      this.openAddEmployees = true;
      this.routeStopIndex = index;
    },
    getLabel(stop_type) {
      switch (stop_type) {
        case "source":
          return "Source";
        case "stop":
          return "Pickup Stop";
        case "destination":
          return "Destination";
        default:
          return "Location";
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.border-expansion {
  border: 1px solid var(--v-primary-base);
}
.v-expansion-panel-content__wrap {
  padding: 0px;
}
.expansion-container-margin {
  margin: 2px;
}

.drop-container-expansion {
  min-height: 150px;
}
</style>