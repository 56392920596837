<template>
  <div class="custom-list">
    <v-row no-gutters class="ma-0">
      <v-col cols="12" class="text-left pa-0">
        <v-card elevation="0" class="rounded-lg w-100 pa-4 light_background">
          <v-row class="d-flex justify-space-between">
            <v-col cols="6" class="d-flex justify-start">
              <h3
                class="text-uppercase text-h6 text_color--text font-weight-bold"
              >
                Route Planning
              </h3>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" class="mt-4 px-4">
        <v-card elevation="0" class="pa-4 rounded-lg">
          <v-row
            v-if="
              formErrors &&
              formErrors.non_field_errors &&
              formErrors.non_field_errors.length > 0
            "
          >
            <v-col cols="12" class="pa-0">
              <v-alert
                color="error"
                class="white--text align-center"
                dismissible
                dense
              >
                <p
                  v-for="(error, i) in formErrors.non_field_errors"
                  :key="i"
                  class="pa-0 d-flex text-center align-center"
                >
                  {{ error }}
                </p>
                <template #close>
                  <v-icon
                    @click="formErrors.non_field_errors = []"
                    color="white"
                    >mdi-close</v-icon
                  >
                </template>
              </v-alert>
            </v-col>
          </v-row>
          <v-col cols="12">
            <v-form
              v-model="isValid"
              ref="routePlanForm"
              @submit.prevent="submitForm"
            >
              <v-row>
                <v-col cols="3">
                  <BaseTextInput
                    id="plan_name"
                    name="plan_name"
                    v-model="routePlanningDetails.plan_name"
                    label="Plan Name"
                    :required="true"
                    :error-messages="
                      formErrors && formErrors.plan_name
                        ? formErrors.plan_name
                        : ''
                    "
                    @input="
                      formErrors && formErrors.plan_name
                        ? delete formErrors.plan_name
                        : ''
                    "
                  ></BaseTextInput>
                </v-col>

                <v-col cols="3">
                  <BaseSelect
                    id="company"
                    name="company"
                    v-model="routePlanningDetails.company"
                    :itemsList="companyList"
                    itemText="name"
                    itemValue="value"
                    :required="true"
                    :isSearchRequired="false"
                    label="Select Organization *"
                    :rules="[(val) => !!val || 'Organization is required']"
                    :error-messages="
                      formErrors && formErrors.company ? formErrors.company : ''
                    "
                    @change="
                      formErrors && formErrors.company
                        ? delete formErrors.company
                        : '',
                        getCompanyAddressesList({
                          company_id: routePlanningDetails.company,
                        }),
                        getCompanyShiftList({
                          company_id: routePlanningDetails.company,
                        })
                    "
                  ></BaseSelect>
                </v-col>
                <v-col cols="3">
                  <BaseSelect
                    id="company_address"
                    name="company_address"
                    v-model="routePlanningDetails.company_address"
                    :itemsList="companyAddressesList"
                    itemText="name"
                    itemValue="value"
                    :required="true"
                    :isSearchRequired="false"
                    label="Select Organization Address *"
                    :rules="[(val) => !!val || 'Organization Address is required']"
                    @change="
                      formErrors && formErrors.company_address
                        ? delete formErrors.company_address
                        : ''
                    "
                  ></BaseSelect>
                </v-col>
                <v-col cols="3">
                  <BaseSelect
                    id="company_shift"
                    name="company_shift"
                    v-model="routePlanningDetails.company_shift"
                    :itemsList="companyShiftList"
                    itemText="name"
                    itemValue="value"
                    :required="true"
                    :isSearchRequired="false"
                    label="Select Organization Shift *"
                    :rules="[(val) => !!val || 'Organization Shift is required']"
                    @change="
                      formErrors && formErrors.company_shift
                        ? delete formErrors.company_shift
                        : ''
                    "
                  ></BaseSelect>
                </v-col>
                <v-col cols="3">
                  <BaseSelect
                    id="route_type"
                    name="route_type"
                    v-model="routePlanningDetails.route_type"
                    :itemsList="routeTypeOptions"
                    itemText="name"
                    itemValue="value"
                    :required="true"
                    :isSearchRequired="false"
                    label="Route Type *"
                    :rules="[(val) => !!val || 'Route Type is required']"
                    @change="
                      formErrors && formErrors.route_type
                        ? delete formErrors.route_type
                        : ''
                    "
                  ></BaseSelect>
                </v-col>

                <v-col cols="9" class="d-flex justify-end align-center">
                  <BaseSecondaryButton
                    id="routePlanningReset"
                    class="mr-2"
                    small
                    @click="resetForm()"
                    >Reset</BaseSecondaryButton
                  >
                  <BaseButton
                    id="routePlanningSubmit"
                    customClass="rounded-lg"
                    small
                    :disabled="!isValid"
                    @click="submitForm()"
                    >Submit</BaseButton
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-card>
      </v-col>

      <v-col cols="12" class="mt-4 px-4">
        <v-card elevation="0" class="rounded-lg">
          <v-card-title>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="3">
                    <BaseTextInput
                      id="searchRoutePlan"
                      name="searchRoutePlan"
                      label="Search"
                      :required="false"
                      v-model="searchRoutes"
                      @keydown.enter="getRoutePlanningList()"
                    ></BaseTextInput>
                  </v-col>
                  <v-col
                    cols="3"
                    class="pa-0 ma-0 d-flex justify-start align-center"
                  >
                    <BaseRefreshList
                      id="refreshList"
                      name="refreshList"
                      :isIcon="true"
                      newClass=" mr-2"
                      @click="getRoutePlanningList()"
                    ></BaseRefreshList>
                    <BaseButton
                      id="filterBtn"
                      x-small
                      fab
                      depressed
                      customClass="rounded-lg btn-small light_color_actions"
                      icon="mdi-filter"
                      iconColor="primary"
                      iconClass="ma-1"
                      iconSize="17px"
                      @click="routePlanningFilterDialog = true"
                    ></BaseButton>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12">
                <AgGridVue
                  @grid-ready="onGridReady"
                  :grid-options="gridOptions"
                  :column-defs="headers"
                  :context="context"
                  :default-col-def="defaultColDef"
                  :row-data="routePlanList"
                  style="width: 100%; height: 400px"
                  class="ag-theme-alpine"
                >
                </AgGridVue>
              </v-col>
              <v-col cols="12" class="pt-3 d-flex justify-end">
                <v-row>
                  <v-col cols="6" class="d-flex align-center justify-start">
                    <BasePaginationCount :total="totalItems" />
                  </v-col>

                  <v-col cols="6" class="d-flex align-center justify-end">
                    <BasePagination
                      :pageNo="pageNo"
                      :totalItems="totalItems"
                      :pageSize="itemsPerPage"
                      @nextPage="nextPage"
                      @prevPage="prevPage"
                      @itemsPerPageChange="itemsPerPageChange"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div id="dialogs">
      <RoutePlanningFilter
        v-model="routePlanningFilterDialog"
        @routePlanningFiltersChanged="getRoutePlanningList()"
      ></RoutePlanningFilter>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";
import { AgGridVue } from "ag-grid-vue";
import RoutePlanningActionsButton from "@/components/AgGridButtons/RoutePlanningActionsButton.vue";
import RoutePlanningFilter from "@/components/ModuleBasedComponents/route_planning/RoutePlanningFilter.vue";
export default {
  components: { AgGridVue, RoutePlanningActionsButton, RoutePlanningFilter },

  data() {
    return {
      routePlanningFilterDialog: false,
      totalItems: 0,
      pageNo: 1,
      totalCount: 0,
      itemsPerPage: 10,

      gridApi: null,
      columnApi: null,
      defaultColDef: {
        lockPosition: true,
        resizable: true,
        flex: 1,
        sortable: true,
        autoHeight: true,
        cellStyle: {
          "text-overflow": "ellipsis",
          "white-space": "nowrap",
          overflow: "hidden",
          display: "block",
        },
      },
      gridOptions: {
        onGridSizeChanged: () => {
          if (this.selectedHeaders <= 6) {
            this.gridOptions.api.sizeColumnsToFit();
          } else {
            setTimeout(() => {
              this.autoSizeAll();
            }, 200);
          }
        },
        headerHeight: 40,
        rowHeight: 40,
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },
      gridApi: null,
      columnApi: null,
      gridOptions: {
        onGridSizeChanged: () => {
          this.gridOptions.api.sizeColumnsToFit();
        },
        headerHeight: 40,
        rowHeight: 40,
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },

      searchRoutes: null,

      routePlanningDetails: {},
      routeTypeOptions: [
        {
          name: "PickUp",
          value: "pick_up",
        },
        {
          name: "Drop",
          value: "drop",
        },
      ],
      isValid: true,
      companyAddressesList: [],
      companyList: [],
      companyShiftList: [],
      formErrors: {},
      routePlanList: [],
      headers: [
        {
          headerName: "Plan Name",
          field: "plan_name",
        },
        {
          headerName: "Organization",
          field: "company",
        },
        {
          headerName: "Organization Location",
          field: "company_address",
        },
        {
          headerName: "Progress",
          field: "progress",
          cellRenderer: (param) => {
            if (param.value > -1) {
              return param.value + "%";
            }
          },
        },
        {
          headerName: "Shift Time",
          field: "shift_timings",
        },
        {
          headerName: "Status",
          field: "status",
        },
        {
          headerName: "Total Passengers",
          field: "total_employees",
        },
        {
          headerName: "Route Type",
          field: "route_type",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "RoutePlanningActionsButton",
        },
      ],
      nonFieldErrors: [],
      interval: null,
      filters: {},
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    totalPages() {
      return Math.ceil(this.totalCount / this.itemsPerPage);
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  methods: {
    nextPage() {
      this.pageNo++;
      this.getRoutePlanningList();
    },
    prevPage() {
      this.pageNo--;
      this.getRoutePlanningList();
    },
    itemsPerPageChange(value) {
      this.pageNo = 1;
      this.itemsPerPage = value;
      this.getRoutePlanningList();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    headersChanged(value) {
      this.headerSelected = value;
    },
    getRoutePlanningList(params) {
      let filters = localStorage.getItem("routePlanningFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if (filters.project_id && filters.project_id.length > 0) {
        filters.project_id = filters.project_id.join(",");
      }
      if (filters.company_id && filters.company_id.length > 0) {
        filters.company_id = filters.company_id.join(",");
      }
      if (filters.company_shift && filters.company_shift.length > 0) {
        filters.company_shift = filters.company_shift.join(",");
      }
      this.filters = filters;
      params = {
        ...params,
        ...this.filters,
        offset: this.offset,
        limit: 10,
      };
      bus.$emit("showLoader", true);
      if (this.searchRoutes) {
        params.search = this.searchRoutes;
      }
      this.$api.route_planning
        .getRoutePlanningList(params)
        .then((res) => {
          bus.$emit("showLoader", false);

          this.routePlanList = res.data;
          this.totalItems = res.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          if (err) {
            bus.$emit("showToastMessage", {
              message: "Something went wrong!",
              color: "error",
            });
          }
        });
    },
    submitForm() {
      if (!this.$refs.routePlanForm.validate()) {
        bus.$emit("showToastMessage", {
          message: "Please correct all the errors to submit the form!",
          color: "error",
        });
        return;
      }
      let data = {
        ...this.routePlanningDetails,
      };
      bus.$emit("showLoader", true);
      this.$api.route_planning
        .createRoutePlan(data)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Route Plan Created!",
            color: "success",
          });
          this.getRoutePlanningList();
          this.resetForm();
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          this.formErrors = err.data;
          if (err.data.non_field_errors) {
            this.formErrors.non_field_errors = err.data.non_field_errors;
          }
        });
    },
    getCompanyList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      bus.$emit("showLoader", true);
      this.$api.company.getCompanyList(params).then((res) => {
        bus.$emit("showLoader", false);
        this.companyList = res.data.map((obj) => {
          return {
            name: obj.name,
            value: obj.company_id,
          };
        });
      });
    },
    getCompanyAddressesList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      bus.$emit("showLoader", true);
      this.$api.company_addresses
        .getCompanyAddressesList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.companyAddressesList = res.data.map((obj) => {
            return {
              name: obj.location_name,
              value: obj.location_id,
            };
          });
        });
    },
    getCompanyShiftList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      bus.$emit("showLoader", true);

      this.$api.company_shifts.getCompanyShiftList(params).then((res) => {
        bus.$emit("showLoader", false);
        this.companyShiftList = res.data.map((obj) => {
          return {
            name: obj.shift_name,
            value: obj.shift_id,
          };
        });
      });
    },
    resetForm() {
      this.routePlanningDetails = {};
      this.formErrors = {};
      this.$refs.routePlanForm.reset();
      this.$refs.routePlanForm.resetValidation();
    },
    viewPlan(id) {
      this.$router.push(`/app/admin/route_planning/view/${id}`);
    },
  },
  mounted() {
    this.getCompanyList();
    this.getRoutePlanningList();
    this.interval = setInterval(() => {
      this.getRoutePlanningList();
    }, 30000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style>
</style>