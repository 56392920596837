var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseFormLayout',{attrs:{"title":`${_vm.editMode ? 'Edit' : 'Add'} Passenger`,"nonFieldErrors":_vm.formErrors.non_field_errors},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(!_vm.editMode)?_c('BaseSecondaryButton',{staticClass:"mr-2 rounded-lg",attrs:{"id":"employeeResetBtn","small":""},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v("Reset")]):_vm._e(),_c('BaseButton',{attrs:{"id":"employeeSubmitBtn","small":"","customClass":"rounded-lg","disabled":!_vm.isValid},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("Submit")])]},proxy:true},{key:"content",fn:function(){return [_c('v-form',{ref:"employeeForm",attrs:{"id":"employeeForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"mx-2"},[_c('v-col',{staticClass:"white rounded-lg",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"pa-2"},[_c('h4',{staticClass:"text-body-1 font-weight-bold black--text"},[_vm._v(" Passenger Basic Details ")])]),_c('v-divider'),_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"cols":"3"}},[_c('BaseTextInput',{attrs:{"id":"username","name":"username","label":"Passenger ID / Username","required":true,"error-messages":_vm.formErrors && _vm.formErrors.username
                        ? _vm.formErrors.username
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.username
                        ? delete _vm.formErrors.username
                        : ''}},model:{value:(_vm.employeeFormDetails.username),callback:function ($$v) {_vm.$set(_vm.employeeFormDetails, "username", $$v)},expression:"employeeFormDetails.username"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseTextInput',{attrs:{"id":"first_name","name":"first_name","label":"First Name","required":true,"error-messages":_vm.formErrors && _vm.formErrors.first_name
                        ? _vm.formErrors.first_name
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.first_name
                        ? delete _vm.formErrors.first_name
                        : ''}},model:{value:(_vm.employeeFormDetails.first_name),callback:function ($$v) {_vm.$set(_vm.employeeFormDetails, "first_name", $$v)},expression:"employeeFormDetails.first_name"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseTextInput',{attrs:{"id":"last_name","name":"last_name","label":"Last Name","required":true,"error-messages":_vm.formErrors && _vm.formErrors.last_name
                        ? _vm.formErrors.last_name
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.last_name
                        ? delete _vm.formErrors.last_name
                        : ''}},model:{value:(_vm.employeeFormDetails.last_name),callback:function ($$v) {_vm.$set(_vm.employeeFormDetails, "last_name", $$v)},expression:"employeeFormDetails.last_name"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"background-white rounded-lg remove-number-spinners",attrs:{"outlined":"","type":"number","min":"0","step":"01","hide-details":"auto","dense":"","label":"Contact number","error-messages":_vm.formErrors && _vm.formErrors.contact_number
                        ? _vm.formErrors.contact_number
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.contact_number
                        ? delete _vm.formErrors.contact_number
                        : ''}},model:{value:(_vm.employeeFormDetails.contact_number),callback:function ($$v) {_vm.$set(_vm.employeeFormDetails, "contact_number", $$v)},expression:"employeeFormDetails.contact_number"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BasePasswordInput',{attrs:{"id":"password","name":"password","label":"Password","required":!_vm.editMode,"error-messages":_vm.formErrors && _vm.formErrors.password
                        ? _vm.formErrors.password
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.password
                        ? delete _vm.formErrors.password
                        : ''}},model:{value:(_vm.employeeFormDetails.password),callback:function ($$v) {_vm.$set(_vm.employeeFormDetails, "password", $$v)},expression:"employeeFormDetails.password"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BasePasswordInput',{attrs:{"id":"confirm_password","name":"confirm_password","label":"Confirm Password","required":!_vm.editMode,"error-messages":_vm.formErrors && _vm.formErrors.confirm_password
                        ? _vm.formErrors.confirm_password
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.confirm_password
                        ? delete _vm.formErrors.confirm_password
                        : ''}},model:{value:(_vm.employeeFormDetails.confirm_password),callback:function ($$v) {_vm.$set(_vm.employeeFormDetails, "confirm_password", $$v)},expression:"employeeFormDetails.confirm_password"}})],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mx-2 mt-6"},[_c('v-col',{staticClass:"white rounded-lg",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"pa-2"},[_c('h4',{staticClass:"text-body-1 font-weight-bold black--text"},[_vm._v(" Organization Details ")])]),_c('v-divider'),_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"cols":"3"}},[_c('BaseSelect',{attrs:{"id":"company","name":"company","label":"Select Organization *","required":true,"itemsList":_vm.companyList,"item-text":"name","item-value":"value","rules":[(val) => !!val || 'Organization is required'],"error-messages":_vm.formErrors && _vm.formErrors.company
                        ? _vm.formErrors.company
                        : '',"isClearable":false},on:{"change":function($event){_vm.formErrors && _vm.formErrors.company
                        ? delete _vm.formErrors.company
                        : '';
                      _vm.getCompanyAddressesList({
                        company_id: _vm.employeeFormDetails.company,
                      });
                      _vm.getCompanyShiftList({
                        company_id: _vm.employeeFormDetails.company,
                      });}},model:{value:(_vm.employeeFormDetails.company),callback:function ($$v) {_vm.$set(_vm.employeeFormDetails, "company", $$v)},expression:"employeeFormDetails.company"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseSelect',{ref:"company_address",attrs:{"id":"company_address","name":"company_address","label":"Select Organization Address *","required":true,"itemsList":_vm.companyAddressList,"rules":[(val) => !!val || 'Organization Address is required'],"itemText":"name","itemValue":"value","error-messages":_vm.formErrors && _vm.formErrors.company_address
                        ? _vm.formErrors.company_address
                        : '',"isClearable":false},on:{"change":function($event){_vm.formErrors && _vm.formErrors.company_address
                        ? delete _vm.formErrors.company_address
                        : ''}},model:{value:(_vm.employeeFormDetails.company_address),callback:function ($$v) {_vm.$set(_vm.employeeFormDetails, "company_address", $$v)},expression:"employeeFormDetails.company_address"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseSelect',{ref:"company_shift",attrs:{"id":"company_shift","name":"company_shift","label":"Select Shift Time *","rules":[(val) => !!val || 'Shift Time is required'],"required":true,"itemsList":_vm.shiftList,"item-text":"name","item-value":"value","error-messages":_vm.formErrors && _vm.formErrors.company_shift
                        ? _vm.formErrors.company_shift
                        : '',"isClearable":false},on:{"change":function($event){_vm.formErrors && _vm.formErrors.company_shift
                        ? delete _vm.formErrors.company_shift
                        : ''}},model:{value:(_vm.employeeFormDetails.company_shift),callback:function ($$v) {_vm.$set(_vm.employeeFormDetails, "company_shift", $$v)},expression:"employeeFormDetails.company_shift"}})],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mx-2 mt-6"},[_c('v-col',{staticClass:"white rounded-lg",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"pa-2"},[_c('h4',{staticClass:"text-body-1 font-weight-bold black--text"},[_vm._v(" Addresses Details ")])]),_c('v-divider'),_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{ref:"home_address",staticClass:"rounded-lg",attrs:{"id":"home_address","name":"home_address","placeholder":"Home Address *","hide-details":"auto","required":true,"dense":"","outlined":"","rules":[(val) => !!val || 'Home Address is required'],"error-messages":_vm.formErrors && _vm.formErrors.home_address
                        ? _vm.formErrors.home_address
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.home_address
                        ? delete _vm.formErrors.home_address
                        : ''}},model:{value:(_vm.employeeFormDetails.home_address),callback:function ($$v) {_vm.$set(_vm.employeeFormDetails, "home_address", $$v)},expression:"employeeFormDetails.home_address"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseSelect',{attrs:{"id":"pickup_address","name":"pickup_address","label":"Preferred Pickup Address *","required":true,"itemsList":_vm.pickupDropList,"rules":[
                      (val) =>
                        !!val || 'Preferred Pickup Address is required',
                    ],"item-text":"name","item-value":"value","error-messages":_vm.formErrors && _vm.formErrors.pickup_address
                        ? _vm.formErrors.pickup_address
                        : '',"isClearable":false},on:{"change":function($event){_vm.formErrors && _vm.formErrors.pickup_address
                        ? delete _vm.formErrors.pickup_address
                        : ''}},model:{value:(_vm.employeeFormDetails.pickup_address),callback:function ($$v) {_vm.$set(_vm.employeeFormDetails, "pickup_address", $$v)},expression:"employeeFormDetails.pickup_address"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseSelect',{attrs:{"id":"drop_address","name":"drop_address","label":"Preferred Drop Address *","required":true,"itemsList":_vm.pickupDropList,"rules":[
                      (val) => !!val || 'Preferred Drop Address is required',
                    ],"item-text":"name","item-value":"value","error-messages":_vm.formErrors && _vm.formErrors.drop_address
                        ? _vm.formErrors.drop_address
                        : '',"isClearable":false},on:{"change":function($event){_vm.formErrors && _vm.formErrors.drop_address
                        ? delete _vm.formErrors.drop_address
                        : ''}},model:{value:(_vm.employeeFormDetails.drop_address),callback:function ($$v) {_vm.$set(_vm.employeeFormDetails, "drop_address", $$v)},expression:"employeeFormDetails.drop_address"}})],1)],1)],1)],1)],1)],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }