<template>
  <BaseListLayout
    ref="listLayout"
    title="Organization Shifts"
    :table-header="headers"
    :table-data="companyShiftsList"
    :hasListActions="true"
    :total="totalItems"
    :context="context"
    @getList="getCompanyShiftsList"
    id="companyShiftSearch"
    name="companyShiftSearch"
  >
    <template #listAction>
      <BaseButton
        id="companyShiftAddBtn"
        small
        customClass="font-weight-bold rounded-lg mr-2"
        @click="addCompanyShift()"
        >Add Organization Shifts</BaseButton
      >
      <BaseButton
        id="companyShiftBulkUploadBtn"
        small
        customClass="font-weight-bold rounded-lg"
        @click="showBulkUploadDialog = true"
        >Bulk Upload Organization Shift(s)</BaseButton
      >
    </template>
    <template #leftFilterSlot>
      <BaseRefreshList
        id="refreshList"
        name="refreshList"
        :isIcon="true"
        newClass="ml-2 mr-2"
        @click="getCompanyShiftsList({ offset: 0, limit: 10 })"
      ></BaseRefreshList>
      <BaseButton
        id="filterBtn"
        x-small
        fab
        depressed
        customClass="rounded-lg btn-small light_color_actions"
        icon="mdi-filter"
        iconColor="primary"
        iconClass="ma-1"
        iconSize="17px"
        @click="openCompanyShiftsFilter = true"
      ></BaseButton>
    </template>
    <template #dialogs>
      <CompanyShiftsFilter
        v-model="openCompanyShiftsFilter"
        @companyShiftFiltersChanged="$refs.listLayout.searchTriggered()"
      ></CompanyShiftsFilter>
      <CompanyShiftsForm
        ref="companyShiftForm"
        :editMode="editMode"
        v-model="openCompanyShiftsForm"
        @refreshList="getCompanyShiftsList({ offset: 0, limit: 10 })"
      />
      <BulkUpload
        uploadTo="company_shift"
        v-model="showBulkUploadDialog"
        :hasBulkActions="true"
        @refreshList="getCompanyShiftsList({ limit: 10, offset: 0 })"
      />
    </template>
  </BaseListLayout>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout.vue";
import CompanyShiftActionsButton from "@/components/AgGridButtons/CompanyShiftActionsButton.vue";
import CompanyShiftsForm from "@/components/ModuleBasedComponents/company_shifts/CompanyShiftsForm.vue";
import CompanyShiftsFilter from "@/components/ModuleBasedComponents/company_shifts/CompanyShiftsFilter.vue";
import BulkUpload from "@/components/BulkUploadComponents/BulkUpload.vue";
import { bus } from "@/main";
export default {
  components: {
    BaseListLayout,
    CompanyShiftActionsButton,
    CompanyShiftsForm,
    CompanyShiftsFilter,
    BulkUpload,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      openCompanyShiftsForm: false,
      showBulkUploadDialog: false,
      openCompanyShiftsFilter: false,
      editMode: false,
      headers: [
        {
          headerName: "Shift ID",
          field: "shift_id",
        },
        {
          headerName: "Shift Name",
          field: "shift_name",
        },
        {
          headerName: "Organization Name",
          field: "company_name",
        },
        {
          headerName: "Shift Start Time",
          field: "from_time",
        },
        {
          headerName: "Shift End Time",
          field: "to_time",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "CompanyShiftActionsButton",
        },
      ],
      companyShiftsList: [],
      companyShiftID: null,
      filters: {},
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getCompanyShiftsList(params = {}) {
      let filters = localStorage.getItem("companyShiftFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if (filters.company_id && filters.company_id.length > 0) {
        filters.company_id = filters.company_id.join(",");
      }
      this.filters = filters;
      params = {
        ...params,
        ...this.filters,
      };
      bus.$emit("showLoader", true);
      this.$api.company_shifts
        .getCompanyShiftList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.companyShiftsList = res?.data;
          this.totalItems = res?.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    headersChanged(value) {
      this.headerSelected = value;
    },

    addCompanyShift() {
      this.editMode = false;
      this.openCompanyShiftsForm = true;
    },

    editCompanyShift(id) {
      this.editMode = true;
      this.openCompanyShiftsForm = true;
      this.$refs.companyShiftForm.getCompanyShiftObject(id);
    },
  },
};
</script>

<style>
</style>