<template>
  <div>
    <div class="white mx-2 rounded-lg mt-3">
      <v-row class="d-flex justify-space-between pa-4">
        <v-col cols="6" class="d-flex justify-start">
          <span v-if="route && route.route_name">
            <h4 class="text-h6 font-weight-bold">
              {{ route.route_name }}
            </h4>
          </span>
          <span class="d-flex align-center">
            <v-chip
              class="ml-2 text-capitalize primary--text font-weight-bold text-caption"
              small
              color="light_color_actions"
              v-if="route && route.route_type"
            >
              {{ route.route_type }}
            </v-chip>
          </span>
        </v-col>
        <v-col class="d-flex justify-end">
          <BaseButton
            small
            customClass="rounded-lg mr-2 font-weight-bold"
            id="tripCreateBtn"
            @click="createTrip(route)"
          >
            Create a Trip</BaseButton
          >
          <!-- <BaseSecondaryButton
            small
            customClass="rounded-lg primary--text"
            icon="mdi-pencil"
            iconColor="primary"
            id="tripEditBtn"
            @click="editRoute(selectedId)"
            >Edit Route</BaseSecondaryButton
          > -->
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="pa-4">
        <v-col cols="">
          <div class="d-flex">
            <span>
              <v-icon small color="light_grey"> mdi-map </v-icon>
            </span>
            <span class="d-flex justify-center align-center ml-1 mt-1">
              <h4 class="text-caption light_grey--text font-weight-medium">
                Project
              </h4>
            </span>
          </div>
          <div v-if="route && route.project">
            <h4 class="text-body-2 font-weight-bold">
              {{ route.project }}
            </h4>
          </div>
        </v-col>
        <v-col cols="">
          <div class="d-flex">
            <span>
              <v-icon small color="light_grey">
                mdi-office-building-marker
              </v-icon>
            </span>
            <span class="d-flex justify-center align-center ml-1 mt-1">
              <h4 class="text-caption light_grey--text font-weight-medium">
                Organization
              </h4>
            </span>
          </div>
          <div v-if="route && route.company">
            <h4 class="text-body-2 font-weight-bold">
              {{ route.company }}
            </h4>
          </div>
        </v-col>
        <v-col cols="">
          <div class="d-flex">
            <span>
              <v-icon small color="light_grey">
                mdi-office-building-marker
              </v-icon>
            </span>
            <span class="d-flex justify-center align-center ml-1 mt-1">
              <h4 class="text-caption light_grey--text font-weight-medium">
                Organization Location
              </h4>
            </span>
          </div>
          <div v-if="route && route.company_address">
            <h4 class="text-body-2 font-weight-bold truncate">
              {{ route.company_address }}
            </h4>
          </div>
        </v-col>
        <v-col cols="">
          <div class="d-flex">
            <span>
              <v-icon small color="light_grey"> mdi-timer-marker </v-icon>
            </span>
            <span class="d-flex justify-center align-center ml-1 mt-1">
              <h4 class="text-caption light_grey--text font-weight-medium">
                Shift Time
              </h4>
            </span>
          </div>
          <div v-if="route && route.shift_time">
            <h4 class="text-body-2 font-weight-bold">
              {{ route.shift_time }}
            </h4>
          </div>
        </v-col>
        <v-col cols="">
          <div class="d-flex">
            <span>
              <v-icon small color="light_grey"> mdi-map </v-icon>
            </span>
            <span class="d-flex justify-center align-center ml-1 mt-1">
              <h4 class="text-caption light_grey--text font-weight-medium">
                Distance
              </h4>
            </span>
          </div>
          <div v-if="route && route.project">
            <h4 class="text-body-2 font-weight-bold">
              {{ route.distance }} KM
            </h4>
          </div>
        </v-col>
        <v-col cols="">
          <div class="d-flex">
            <span>
              <v-icon small color="light_grey"> mdi-clock </v-icon>
            </span>
            <span class="d-flex justify-center align-center ml-1 mt-1">
              <h4 class="text-caption light_grey--text font-weight-medium">
                Duration
              </h4>
            </span>
          </div>
          <div v-if="route && route.project">
            <h4 class="text-body-2 font-weight-bold">
              {{ route.duration_time }}
            </h4>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-row class="white mx-2 rounded-lg mt-5 d-flex justify-space-between px-2">
      <v-col cols="6" class="d-flex justify-start align-center">
        <h4 class="font-weight-bold black--text text-body-1">
          {{ routesTab == 0 ? "Map" : routesTab == 1 ? "Stops" : "Trips" }}
        </h4>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn-toggle
          id="routesToggle"
          v-model="routesTab"
          dense
          mandatory
          tile
          color="black"
          class="btnToggle"
          group
        >
          <v-btn id="routesMap" @click="setRouteOnMap()">
            <h5 class="text-caption black--text font-weight-bold">Map</h5>
          </v-btn>

          <v-btn id="routesStops" @click="getRouteStops(selectedId)">
            <h5 class="text-caption black--text font-weight-bold">Stops</h5>
          </v-btn>

          <v-btn id="routesTrip">
            <h5 class="text-caption black--text font-weight-bold">Trips</h5>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <v-row v-if="routesTab == 0" class="white mx-2 rounded-lg mt-5 px-4">
      <v-col cols="12" class="mx-0-0 px-0">
        <GoogleMap
          mapWidth="100%"
          mapHeight="calc(100vh - 380px)"
          ref="routeMap"
        ></GoogleMap>
      </v-col>
    </v-row>

    <v-row v-if="routesTab == 1" class="white mx-2 rounded-lg mt-5 px-4">
      <v-col cols="12" v-if="routeStops && routeStops.length > 0">
        <RouteStops :stops="routeStops"></RouteStops>
      </v-col>
    </v-row>

    <v-row v-if="routesTab == 2" class="white mx-2 rounded-lg mt-5 px-0">
      <v-col cols="12" class="ma-0 pa-0">
        <BaseListLayout
          :titleSection="false"
          :gridStyle="gridStyle"
          :tableHeader="headers"
          :tableData="routeTrips"
          :total="totalItems"
          :context="context"
          @getList="getRouteTrips"
          id="routesSearch"
          name="routesSearch"
          btnID="routesBtn"
        >
          <template #leftFilterSlot>
            <BaseRefreshList
              id="refreshList"
              name="refreshList"
              :isIcon="true"
              newClass="ml-2"
              @click="getRouteTrips()"
            ></BaseRefreshList>
            <RouteTripFilter color="light_color_actions"></RouteTripFilter>
          </template>
        </BaseListLayout>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GoogleMap from "@/components/BaseComponents/GoogleMap.vue";
import RouteTrips from "@/components/ModuleBasedComponents/routes/RouteTrips.vue";
import RoutesTripActionsButton from "@/components/AgGridButtons/RoutesTripActionsButton.vue";
import RouteStops from "./RouteStops.vue";
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout.vue";
import RouteTripFilter from "./RouteTripFilter.vue";
import { bus } from "@/main";
export default {
  components: {
    GoogleMap,
    RouteTrips,
    RoutesTripActionsButton,
    RouteStops,
    BaseListLayout,
    RouteTripFilter,
  },
  props: {
    route: {
      type: Object,
    },
    selectedId: {
      type: Number,
    },
  },
  data() {
    return {
      routesTab: 0,

      headers: [
        {
          headerName: "Trip ID",
          field: "reference_number",
        },
        {
          headerName: "Driver",
          field: "driver",
        },
        {
          headerName: "Vehicle",
          field: "vehicle",
        },
        {
          headerName: "Start Date",
          field: "from_date",
        },
        {
          headerName: "End Date",
          field: "to_date",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "RoutesTripActionsButton",
        },
      ],
      routeTrips: [],
      routeStops: [],

      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      gridStyle: {
        width: "100%",
        height: "calc(100vh - 570px)",
      },
    };
  },
  watch: {
    selectedId(val) {
      if (val) {
        this.routesTab = 0;
        this.setRouteOnMap();
      }
    },
    routesTab(val) {
      if (val == 0) {
        this.setRouteOnMap();
      }
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    createTrip(data) {
      let dataObj;
      dataObj = data;
      this.$router.push({
        name: "trips/create", //use name for router push
        params: { data: dataObj },
      });
    },
    editRoute(id) {
      this.$router.push(`/app/admin/routes/edit/${id}`);
    },

    getRouteStops(id) {
      bus.$emit("showLoader", true);
      this.$api.routes
        .getRouteStops(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.routeStops = res.data;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },

    getRouteTrips(params) {
      params = {
        ...params,
        route: this.selectedId,
      };
      bus.$emit("showLoader", true);
      this.$api.trips
        .getTripList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.routeTrips = res.data;
          this.totalItems = res.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },

    setRouteOnMap() {
      let id = this.route.id;
      this.$api.routes
        .getRoute(id)
        .then((res) => {
          let timer = setTimeout(() => {
            if (this.$refs && this.$refs.routeMap) {
              this.$refs.routeMap.clearMarker();
              this.$refs.routeMap.clearGeoJson();

              setTimeout(() => {
                if (res && res.data && res.data.planned_route) {
                  this.$refs.routeMap.setPlannedRoute(
                    res.data.planned_route,
                    "route_stops"
                  );
                }
              }, 100);
            }
          }, 400);
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // view trips of the route
    viewDetails(id) {
      this.$router.push(`/app/admin/trips/detail/${id}`);
    },
  },
  mounted() {
    if (this.selectedId) {
      setTimeout(() => {
        this.setRouteOnMap();
      }, 100);
    }
  },
};
</script>

<style>
.btnToggle .v-btn .v-item--active {
  background-color: black;
  color: white;
}
.stopsHeight {
  height: calc(100vh - 500px);
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>