var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialog',{attrs:{"title":`${_vm.editMode ? 'Edit' : 'Add'} Shift`,"width":"70vh","nonFieldErrors":_vm.formErrors.non_field_errors},on:{"closeDialog":function($event){return _vm.resetForm()}},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c('v-form',{ref:"companyShiftForm",attrs:{"id":"companyShiftForm","name":"companyShiftForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('BaseTextInput',{attrs:{"id":"shift_id","name":"shift_id","required":true,"label":"Shift ID","error-messages":_vm.formErrors && _vm.formErrors.shift_id ? _vm.formErrors.shift_id : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.shift_id
                ? delete _vm.formErrors.shift_id
                : ''}},model:{value:(_vm.companyShift.shift_id),callback:function ($$v) {_vm.$set(_vm.companyShift, "shift_id", $$v)},expression:"companyShift.shift_id"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('BaseTextInput',{attrs:{"id":"shift_name","name":"shift_name","required":true,"label":"Shift Name","error-messages":_vm.formErrors && _vm.formErrors.shift_name ? _vm.formErrors.shift_name : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.shift_name
                ? delete _vm.formErrors.shift_name
                : ''}},model:{value:(_vm.companyShift.shift_name),callback:function ($$v) {_vm.$set(_vm.companyShift, "shift_name", $$v)},expression:"companyShift.shift_name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('BaseSelect',{attrs:{"id":"company","name":"company","required":true,"label":"Select Organization *","itemsList":_vm.companyList,"itemText":"name","itemValue":"value","rules":[(val) => !!val || 'Organization is required'],"error-messages":_vm.formErrors && _vm.formErrors.company ? _vm.formErrors.company : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.company
                ? delete _vm.formErrors.company
                : ''}},model:{value:(_vm.companyShift.company),callback:function ($$v) {_vm.$set(_vm.companyShift, "company", $$v)},expression:"companyShift.company"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"background-white rounded-lg",attrs:{"label":"Shift Start Time *","outlined":"","dense":"","type":"time","hide-details":"auto","name":"from_time","error-messages":_vm.formErrors && _vm.formErrors.from_time ? _vm.formErrors.from_time : '',"rules":[(v) => !!v || 'Shift Start Time is required']},on:{"input":function($event){_vm.formErrors && _vm.formErrors.from_time
                ? delete _vm.formErrors.from_time
                : ''}},model:{value:(_vm.companyShift.from_time),callback:function ($$v) {_vm.$set(_vm.companyShift, "from_time", $$v)},expression:"companyShift.from_time"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"background-white rounded-lg",attrs:{"label":"Shift End Time *","outlined":"","dense":"","type":"time","hide-details":"auto","name":"to_time","error-messages":_vm.formErrors && _vm.formErrors.to_time ? _vm.formErrors.to_time : '',"rules":[(v) => !!v || 'Shift End Time is required']},on:{"input":function($event){_vm.formErrors && _vm.formErrors.to_time
                ? delete _vm.formErrors.to_time
                : ''}},model:{value:(_vm.companyShift.to_time),callback:function ($$v) {_vm.$set(_vm.companyShift, "to_time", $$v)},expression:"companyShift.to_time"}})],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [(!_vm.isValid)?_c('BaseSecondaryButton',{attrs:{"customClass":"rounded-lg","small":"","id":"companyShiftResetBtn"},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v("Reset")]):_vm._e(),_c('BaseButton',{attrs:{"disabled":!_vm.isValid,"customClass":"rounded-lg","small":"","id":"companyShiftSubmitBtn"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("Submit")])]},proxy:true}]),model:{value:(_vm.openCompanyShiftForm),callback:function ($$v) {_vm.openCompanyShiftForm=$$v},expression:"openCompanyShiftForm"}})
}
var staticRenderFns = []

export { render, staticRenderFns }