<template>
  <BaseDetailLayout title="Organization Details">
    <template #detail>
      <v-row no-gutters class="ma-0 px-3 pt-3">
        <v-col cols="12" md="3" lg="3" class="d-flex flex-column">
          <v-card elevation="0" class="primary rounded-lg">
            <v-row no-gutters v-if="companyDetail.company_details" class="pa-2">
              <v-col cols="4" md="3" lg="4" class="pa-2">
                <img
                  :src="CompanyImage"
                  height="
                  100px
                  "
                  width="
                  100px
                  "
                />
              </v-col>
              <v-col class="pa-4 d-flex flex-column justify-space-between">
                <div>
                  <h4 class="text-caption white--text">Organization Name</h4>
                  <h5 class="white--text">
                    {{ companyDetail.company_details.company_name }}
                  </h5>
                </div>
                <div>
                  <h4 class="text-caption white--text">Organization ID</h4>
                  <h5 class="white--text">
                    {{ companyDetail.company_details.company_id }}
                  </h5>
                </div>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="pa-0 ma-0" elevation="0">
            <v-row class="pa-0 ma-0" no-gutters>
              <v-col cols="12" md="12" lg="12" class="pa-4">
                <h4 class="text-caption">Project</h4>
                <h5 class="text-body-2 font-weight-bold">
                  {{ companyDetail.company_details.project }}
                </h5>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                class="d-flex flex-column justify-start px-4 pb-2"
              >
                <h4 class="text-caption">City</h4>
                <h5 class="text-body-2 font-weight-bold">
                  {{ companyDetail.company_details.city }}
                </h5>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                class="d-flex flex-column justify-end px-4 pb-2"
              >
                <h4 class="text-caption d-flex justify-end">Country</h4>
                <h5 class="text-body-2 font-weight-bold d-flex justify-end">
                  {{ companyDetail.company_details.country }}
                </h5>
              </v-col>
            </v-row>
          </v-card>

          <v-card elevation="0" class="mt-4 rounded-lg">
            <v-card-title>
              <h4 class="text-body-2 font-weight-bold">Organization Details</h4>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle>
              <div
                v-if="
                  companyDetail.company_details &&
                  companyDetail.company_details.contact_details
                "
                class="overflow-y-auto company-details-container"
              >
                <v-row
                  no-gutters
                  v-for="(value, key, index) in companyDetail.company_details
                    .contact_details"
                  :key="index"
                  class="pt-1 px-2"
                  v-if="value"
                >
                  <v-col md="5" lg="6">
                    <h4 class="text-capitalize text-caption black--text">
                      {{ key.replace(/_/g, " ") }}
                    </h4>
                  </v-col>
                  <v-col md="7" lg="6" class="d-flex justify-end">
                    <h5>{{ value }}</h5>
                  </v-col>
                </v-row>
              </div>
            </v-card-subtitle>
          </v-card>

          <v-card elevation="0" class="mt-4 rounded-lg">
            <v-card-title>
              <h4 class="text-body-2 font-weight-bold">Organization Shifts</h4>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle>
              <div
                v-if="
                  companyDetail.company_details &&
                  companyDetail.company_details.company_shifts
                "
                class="overflow-y-auto"
                :style="
                  $vuetify.breakpoint.lg
                    ? 'height: calc(100vh - 720px)'
                    : $vuetify.breakpoint.md
                    ? 'height: calc(100vh - 100px)'
                    : ''
                "
              >
                <table style="width: 100%">
                  <thead>
                    <tr>
                      <th><h4 class="text-caption px-2">#</h4></th>
                      <th class="text-start">
                        <h4 class="text-caption px-2">Shift Name</h4>
                      </th>
                      <th class="d-flex justify-end">
                        <h4 class="text-caption px-2">Shift Time</h4>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="">
                    <tr
                      v-for="(shift, index) in companyDetail.company_details
                        .company_shifts"
                      :key="index"
                    >
                      <td class="px-2 text-caption">
                        <h4>
                          {{ index + 1 }}
                        </h4>
                      </td>
                      <td class="text-caption">
                        <h4>
                          {{ shift.shift_name }}
                        </h4>
                      </td>
                      <td class="d-flex justify-end text-caption">
                        <h4>
                          {{ shift.shift_time }}
                        </h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-card-subtitle>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="6">
          <section class="d-flex flex-column">
            <v-row
              no-gutters
              class="pb-3 px-3"
              v-if="companyDetail && companyDetail.company_details"
            >
              <v-col
                v-for="(value, key, index) in companyDetail.company_details
                  .company_analytics"
                :key="index"
                class="px-1 pb-1"
              >
                <v-card elevation="0" class="rounded-lg">
                  <v-card-text class="py-2">
                    <span
                      class="text-capitalize text-start text-body-2 font-weight-bold d-flex justify-start"
                    >
                      {{ key.replace(/_/g, "  ") }}
                    </span>
                    <div class="mt-2 d-flex align-center justify-start">
                      <div
                        class="icon-small light_primary d-flex justify-center align-center"
                      >
                        <v-icon color="primary" small>
                          {{ companyAnalyticsIcon[key] }}</v-icon
                        >
                      </div>

                      <span class="text-caption px-4 my-1">
                        <h3>{{ value }}</h3>
                      </span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="px-3" no-gutters>
              <v-col cols="12" class="px-1">
                <v-card class="rounded-lg" elevation="0">
                  <v-card-title>
                    <h4 class="text-body-2 font-weight-bold">
                      Organization Locations
                    </h4>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text
                    style="height: calc(100vh - 316px)"
                    class="overflow-y-auto"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-row no-gutters>
                          <v-col cols="3">
                            <h4 class="text-caption font-weight-bold">
                              Location
                            </h4>
                          </v-col>
                          <v-col cols="3">
                            <h4 class="text-caption font-weight-bold">
                              Address
                            </h4>
                          </v-col>
                          <v-col cols="3">
                            <h4 class="text-caption font-weight-bold">
                              Contact Person
                            </h4>
                          </v-col>
                          <v-col cols="3">
                            <h4 class="text-caption font-weight-bold">
                              Shift(s)
                            </h4>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col
                        cols="12"
                        v-for="(company_location, index) in companyLocations"
                        :key="index"
                      >
                        <v-col cols="12" class="pa-0 ma-0">
                          <div class="icon-small">
                            <v-icon small color="primary"
                              >mdi-office-building-marker</v-icon
                            >
                          </div>
                        </v-col>
                        <v-row no-gutters>
                          <v-col cols="3">
                            <h4 class="text-body-2 font-weight-bold">
                              {{ company_location.location_id }}
                            </h4>
                            <h4
                              class="text-caption font-weight-bold light_grey--text"
                            >
                              {{ company_location.location_name }}
                            </h4>
                            <h4
                              class="text-caption font-weight-bold light_grey--text"
                            >
                            Passengers: {{ company_location.no_of_employees }}
                            </h4>
                          </v-col>

                          <v-col cols="3" class="pr-2">
                            <h4
                              class="text-caption font-weight-bold light_grey--text"
                            >
                              {{ company_location.address }}
                            </h4>
                            <h4
                              class="text-caption font-weight-bold light_grey--text"
                            >
                              {{ company_location.coordinates }}
                            </h4>
                          </v-col>

                          <v-col cols="3">
                            <h4
                              class="text-caption font-weight-bold light_grey--text"
                            >
                              {{ company_location.contact_person }}
                            </h4>
                            <h4
                              class="text-caption font-weight-bold light_grey--text"
                            >
                              {{ company_location.contact_email }}
                            </h4>
                            <h4
                              class="text-caption font-weight-bold light_grey--text"
                            >
                              {{ company_location.contact_number }}
                            </h4>
                          </v-col>

                          <v-col cols="3">
                            <h4
                              v-for="(
                                company_shift, index
                              ) in company_location.company_shifts"
                            >
                              <span
                                class="text-caption font-weight-bold light_grey--text"
                                >{{ company_shift.shift_time }}</span
                              >
                              <span
                                class="text-caption font-weight-bold light_grey--text"
                              >
                                |
                              </span>
                              <span
                                ><v-icon x-small color="light_grey">
                                  mdi-account
                                </v-icon></span
                              >
                              <span
                                class="text-caption font-weight-bold light_grey--text"
                                >{{ company_shift.no_of_employees }}</span
                              >
                            </h4>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </section>
        </v-col>

        <v-col cols="12" md="3" lg="3" class="d-flex flex-column">
          <v-card elevation="0" class="rounded-lg">
            <v-card-title
              ><h4 class="text-body-2 font-weight-bold">Logs</h4></v-card-title
            >
            <v-divider></v-divider>
            <v-card-text
              style="height: calc(100vh - 225px)"
              class="overflow-y-auto"
            >
              <BaseLogs :logs="companyLogs" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </BaseDetailLayout>
</template>

<script>
import CompanyImage from "@/assets/company-image.png";
export default {
  data() {
    return {
      CompanyImage,
      companyDetail: {
        company_details: {
          company_name: "International IT Organization",
          company_id: "COM0012",
          project: "Ahmedabad City Area Project",
          city: "Ahmedabad",
          country: "India",
          contact_details: {
            contact_no: "97800000000",
            email: "com0012@gmail.com",
            website: "www.xyz.com",
          },
          company_shifts: [
            {
              shift_name: "IT Morning Shift",
              shift_time: "09:00 AM - 05:00 PM",
            },
            {
              shift_name: "IT Night Shift",
              shift_time: "06:00 PM - 08:00 AM",
            },
            {
              shift_name: "IT Night Shift",
              shift_time: "06:00 PM - 08:00 AM",
            },
            {
              shift_name: "IT Night Shift",
              shift_time: "06:00 PM - 08:00 AM",
            },
            {
              shift_name: "IT Night Shift",
              shift_time: "06:00 PM - 08:00 AM",
            },
            {
              shift_name: "IT Night Shift",
              shift_time: "06:00 PM - 08:00 AM",
            },
            {
              shift_name: "IT Night Shift",
              shift_time: "06:00 PM - 08:00 AM",
            },
            {
              shift_name: "IT Night Shift",
              shift_time: "06:00 PM - 08:00 AM",
            },
            {
              shift_name: "IT Night Shift",
              shift_time: "06:00 PM - 08:00 AM",
            },
            {
              shift_name: "IT Night Shift",
              shift_time: "06:00 PM - 08:00 AM",
            },
            {
              shift_name: "IT Night Shift",
              shift_time: "06:00 PM - 08:00 AM",
            },
            {
              shift_name: "IT Night Shift",
              shift_time: "06:00 PM - 08:00 AM",
            },
            {
              shift_name: "IT Night Shift",
              shift_time: "06:00 PM - 08:00 AM",
            },
            {
              shift_name: "IT Night Shift",
              shift_time: "06:00 PM - 08:00 AM",
            },
          ],
          company_analytics: {
            locations: 4,
            shifts: 2,
            employees: 50,
            created_on: "23/03/2023",
          },
        },
      },
      companyAnalyticsIcon: {
        locations: "mdi-office-building-marker",
        shifts: "mdi-timer-marker",
        employees: "mdi-account",
        created_on: "mdi-calendar-range",
      },
      companyLogs: [
        {
          added_by: "Admin",
          message: "Organization Created!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },

        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },

        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
        {
          added_by: "Admin",
          message: "Organization Updated!",
          created: "23/03/2023",
        },
      ],
      companyLocations: [
        {
          location_id: "LCT00041741",
          location_name: "Fero Tech Ahmedabad",
          no_of_employees: 200,
          address: "401, Satya One Complex, Memnagar, Ahmedabad, India",
          coordinates: "23.04, 72.53",
          contact_person: "Rakesh Sisodiya",
          contact_email: "abc@xyz.com",
          contact_number: "+91 724 041 1521",
          company_shifts: [
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
          ],
        },
        {
          location_id: "LCT00041741",
          location_name: "Fero Tech Ahmedabad",
          no_of_employees: 200,
          address: "401, Satya One Complex, Memnagar, Ahmedabad, India",
          coordinates: "23.04, 72.53",
          contact_person: "Rakesh Sisodiya",
          contact_email: "abc@xyz.com",
          contact_number: "+91 724 041 1521",
          company_shifts: [
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
          ],
        },
        {
          location_id: "LCT00041741",
          location_name: "Fero Tech Ahmedabad",
          no_of_employees: 200,
          address: "401, Satya One Complex, Memnagar, Ahmedabad, India",
          coordinates: "23.04, 72.53",
          contact_person: "Rakesh Sisodiya",
          contact_email: "abc@xyz.com",
          contact_number: "+91 724 041 1521",
          company_shifts: [
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
          ],
        },
        {
          location_id: "LCT00041741",
          location_name: "Fero Tech Ahmedabad",
          no_of_employees: 200,
          address: "401, Satya One Complex, Memnagar, Ahmedabad, India",
          coordinates: "23.04, 72.53",
          contact_person: "Rakesh Sisodiya",
          contact_email: "abc@xyz.com",
          contact_number: "+91 724 041 1521",
          company_shifts: [
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
          ],
        },
        {
          location_id: "LCT00041741",
          location_name: "Fero Tech Ahmedabad",
          no_of_employees: 200,
          address: "401, Satya One Complex, Memnagar, Ahmedabad, India",
          coordinates: "23.04, 72.53",
          contact_person: "Rakesh Sisodiya",
          contact_email: "abc@xyz.com",
          contact_number: "+91 724 041 1521",
          company_shifts: [
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
          ],
        },
        {
          location_id: "LCT00041741",
          location_name: "Fero Tech Ahmedabad",
          no_of_employees: 200,
          address: "401, Satya One Complex, Memnagar, Ahmedabad, India",
          coordinates: "23.04, 72.53",
          contact_person: "Rakesh Sisodiya",
          contact_email: "abc@xyz.com",
          contact_number: "+91 724 041 1521",
          company_shifts: [
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
          ],
        },
        {
          location_id: "LCT00041741",
          location_name: "Fero Tech Ahmedabad",
          no_of_employees: 200,
          address: "401, Satya One Complex, Memnagar, Ahmedabad, India",
          coordinates: "23.04, 72.53",
          contact_person: "Rakesh Sisodiya",
          contact_email: "abc@xyz.com",
          contact_number: "+91 724 041 1521",
          company_shifts: [
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
          ],
        },
        {
          location_id: "LCT00041741",
          location_name: "Fero Tech Ahmedabad",
          no_of_employees: 200,
          address: "401, Satya One Complex, Memnagar, Ahmedabad, India",
          coordinates: "23.04, 72.53",
          contact_person: "Rakesh Sisodiya",
          contact_email: "abc@xyz.com",
          contact_number: "+91 724 041 1521",
          company_shifts: [
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
          ],
        },
        {
          location_id: "LCT00041741",
          location_name: "Fero Tech Ahmedabad",
          no_of_employees: 200,
          address: "401, Satya One Complex, Memnagar, Ahmedabad, India",
          coordinates: "23.04, 72.53",
          contact_person: "Rakesh Sisodiya",
          contact_email: "abc@xyz.com",
          contact_number: "+91 724 041 1521",
          company_shifts: [
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
            {
              shift_time: "09:00 AM - 05:00 PM",
              no_of_employees: 50,
            },
          ],
        },
      ],
    };
  },
};
</script>

<style>
.icon-small {
  min-width: 30px !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 10px !important;
  align-content: center !important;
}
.company-details-container {
  height: calc(100vh - 715px);
}

@media screen and (max-width: 1300px) {
  .company-details-container {
    height: calc(100vh - 300px);
  }
}
</style>