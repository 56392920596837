<template>
  <BaseDialog
    v-model="openCompanyShiftForm"
    :title="`${editMode ? 'Edit' : 'Add'} Shift`"
    width="70vh"
    :nonFieldErrors="formErrors.non_field_errors"
    @closeDialog="resetForm()"
  >
    <template #dialogContent>
      <v-form
        v-model="isValid"
        ref="companyShiftForm"
        id="companyShiftForm"
        name="companyShiftForm"
      >
        <v-row>
          <v-col cols="12">
            <BaseTextInput
              id="shift_id"
              name="shift_id"
              v-model="companyShift.shift_id"
              :required="true"
              label="Shift ID"
              :error-messages="
                formErrors && formErrors.shift_id ? formErrors.shift_id : ''
              "
              @input="
                formErrors && formErrors.shift_id
                  ? delete formErrors.shift_id
                  : ''
              "
            ></BaseTextInput>
          </v-col>
          <v-col cols="12">
            <BaseTextInput
              id="shift_name"
              name="shift_name"
              v-model="companyShift.shift_name"
              :required="true"
              label="Shift Name"
              :error-messages="
                formErrors && formErrors.shift_name ? formErrors.shift_name : ''
              "
              @input="
                formErrors && formErrors.shift_name
                  ? delete formErrors.shift_name
                  : ''
              "
            ></BaseTextInput>
          </v-col>
          <v-col cols="12">
            <BaseSelect
              id="company"
              name="company"
              v-model="companyShift.company"
              :required="true"
              label="Select Organization *"
              :itemsList="companyList"
              itemText="name"
              itemValue="value"
              :rules="[(val) => !!val || 'Organization is required']"
              :error-messages="
                formErrors && formErrors.company ? formErrors.company : ''
              "
              @input="
                formErrors && formErrors.company
                  ? delete formErrors.company
                  : ''
              "
            ></BaseSelect>
          </v-col>
          <v-col cols="6">
            <!-- <BaseTimeInput
              id="from_time"
              name="from_time"
              v-model="companyShift.from_time"
              :required="true"
              label="Shift Start Time"
              :error-messages="
                formErrors && formErrors.from_time ? formErrors.from_time : ''
              "
              @input="
                formErrors && formErrors.from_time
                  ? delete formErrors.from_time
                  : ''
              "
            ></BaseTimeInput> -->
            <v-text-field
              label="Shift Start Time *"
              outlined
              dense
              type="time"
              class="background-white rounded-lg"
              hide-details="auto"
              name="from_time"
              :error-messages="
                formErrors && formErrors.from_time ? formErrors.from_time : ''
              "
              @input="
                formErrors && formErrors.from_time
                  ? delete formErrors.from_time
                  : ''
              "
              v-model="companyShift.from_time"
              :rules="[(v) => !!v || 'Shift Start Time is required']"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <!-- <BaseTimeInput
              id="to_time"
              name="to_time"
              v-model="companyShift.to_time"
              :required="true"
              label="Shift End Time"
              :error-messages="
                formErrors && formErrors.to_time ? formErrors.to_time : ''
              "
              @input="
                formErrors && formErrors.to_time
                  ? delete formErrors.to_time
                  : ''
              "
            ></BaseTimeInput> -->
            <v-text-field
              label="Shift End Time *"
              outlined
              dense
              type="time"
              class="background-white rounded-lg"
              hide-details="auto"
              name="to_time"
              :error-messages="
                formErrors && formErrors.to_time ? formErrors.to_time : ''
              "
              @input="
                formErrors && formErrors.to_time
                  ? delete formErrors.to_time
                  : ''
              "
              v-model="companyShift.to_time"
              :rules="[(v) => !!v || 'Shift End Time is required']"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #actions>
      <BaseSecondaryButton
        v-if="!isValid"
        customClass="rounded-lg"
        small
        id="companyShiftResetBtn"
        @click="resetForm()"
        >Reset</BaseSecondaryButton
      >
      <BaseButton
        :disabled="!isValid"
        customClass="rounded-lg"
        small
        id="companyShiftSubmitBtn"
        @click="submitForm()"
        >Submit</BaseButton
      >
    </template>
  </BaseDialog>
</template>

<script>
import { bus } from "@/main";
export default {
  props: {
    value: Boolean,
    editMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      companyShift: {},
      formErrors: {
        non_field_errors: [],
      },
      isValid: true,
      companyList: [],
    };
  },
  watch: {
    openCompanyShiftForm(val) {
      if (val) {
        this.getCompanyList();
      }
    },
  },
  computed: {
    openCompanyShiftForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getCompanyShiftObject(id = null) {
      bus.$emit("showLoader", true);
      this.$api.company_shifts
        .getCompanyShiftObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.companyShift = res.data;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },

    getCompanyList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.company.getCompanyList(params).then((res) => {
        this.companyList = res.data.map((obj) => {
          return {
            name: obj.name,
            value: obj.company_id,
          };
        });
      });
    },

    submitForm() {
      let data = { ...this.companyShift };
      bus.$emit("showLoader", true);
      if (!this.editMode) {
        this.$api.company_shifts
          .createCompanyShift(data)
          .then((res) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Organization Shift Created!",
              color: "success",
            });
            this.openCompanyShiftForm = false;
            this.$emit("refreshList");
            this.resetForm();
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
            if (err.data.non_field_errors) {
              this.formErrors.non_field_errors = err.data.non_field_errors;
            }
          });
      } else {
        this.$api.company_shifts
          .updateCompanyShiftObject({ id: this.companyShift.id, data: data })
          .then((res) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Organization Shift Updated!",
              color: "success",
            });
            this.openCompanyShiftForm = false;
            this.$emit("refreshList");
            this.resetForm();
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
            if (err.data.non_field_errors) {
              this.formErrors.non_field_errors = err.data.non_field_errors;
            }
          });
      }
    },

    resetForm() {
      this.formErrors = {
        non_field_errors: [],
      };
      this.companyShift = {};
      this.$refs.companyShiftForm.reset();
      this.$refs.companyShiftForm.resetValidation();
    },
  },
};
</script>

<style>
</style>