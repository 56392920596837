import AdminDashboard from "../pages/admin/dashboard/index.vue";

const Dashboard = [
  {
    path: "dashboard",
    name: "dashboard",
    component: AdminDashboard,
  },
];

export default Dashboard;
