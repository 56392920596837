<template>
  <BaseDetailLayout title="Trip Details">
    <template #detail>
      <v-row no-gutters class="ma-0 px-3 pt-3">
        <v-col cols="12" md="3" lg="3" class="d-flex flex-column">
          <v-card elevation="0" class="primary rounded-lg">
            <v-row no-gutters v-if="tripDetail.trip_details" class="pa-2">
              <v-col cols="4" md="3" lg="4" class="pa-2">
                <img
                  :src="TripImage"
                  height="
                  100px
                  "
                  width="
                  100px
                  "
                />
              </v-col>
              <v-col class="pa-4 d-flex flex-column justify-space-between">
                <div>
                  <h4 class="text-caption white--text">Trip ID</h4>
                  <h5 class="white--text">
                    {{ tripDetail.trip_details.trip_id }}
                  </h5>
                </div>
                <div class="d-flex justify-space-between">
                  <div>
                    <h4 class="text-caption white--text">Route Name</h4>
                    <h5 class="white--text">
                      {{ tripDetail.trip_details.route_name }}
                    </h5>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="pa-0 ma-0" elevation="0">
            <v-row class="pa-0 ma-0" no-gutters>
              <v-col cols="12" md="12" lg="12" class="pa-4">
                <h4 class="text-caption">Driver</h4>
                <h5 class="text-body-2 font-weight-bold">
                  {{ tripDetail.trip_details.driver }}
                </h5>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                class="d-flex flex-column justify-start px-4 pb-2"
              >
                <h4 class="text-caption">Vehicle</h4>
                <h5 class="text-body-2 font-weight-bold">
                  {{ tripDetail.trip_details.vehicle }}
                </h5>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                class="d-flex flex-column justify-end px-4 pb-2"
              >
                <h4 class="text-caption d-flex justify-end">Duration</h4>
                <h5 class="text-body-2 font-weight-bold d-flex justify-end">
                  {{ tripDetail.trip_details.duration }}
                </h5>
              </v-col>
            </v-row>
          </v-card>

          <v-card elevation="0" class="mt-4 rounded-lg">
            <v-card-title>
              <h4 class="text-body-2 font-weight-bold">Stoppages</h4>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle>
              <div
                class="overflow-y-auto overflow-x-hidden"
                style="height: calc(100vh - 540px)"
              >
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    v-for="(stoppage, index) in tripDetail.trip_details
                      .stoppages"
                    :key="index"
                  >
                    <v-row class="pa-0">
                      <v-col cols="1">
                        <v-icon small>mdi-information</v-icon>
                      </v-col>
                      <v-col cols="11">
                        <div>
                          <span
                            class="text-caption font-weight-bold black--text"
                            >{{ stoppage.time }}</span
                          >
                          <span
                            class="text-caption font-weight-bold light_grey--text"
                            >&nbsp; | &nbsp;</span
                          >
                          <span
                            class="text-caption font-weight-bold light_grey--text"
                            >{{ stoppage.stop_type }}</span
                          >
                        </div>
                        <div>
                          <h4 class="text-caption font-weight-bold black--text">
                            {{ stoppage.address }}
                          </h4>
                          <h4 class="text-caption font-weight-bold black--text">
                            {{ stoppage.coordinates }}
                          </h4>
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
              </div>
            </v-card-subtitle>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="6">
          <section class="d-flex flex-column">
            <v-row
              no-gutters
              class="pb-3 px-3"
              v-if="tripDetail && tripDetail.trip_details"
            >
              <v-col
                v-for="(value, key, index) in tripDetail.trip_details
                  .trip_analytics"
                :key="index"
                class="px-1 pb-1"
              >
                <v-card elevation="0" class="rounded-lg">
                  <v-card-text class="py-2">
                    <span
                      class="text-capitalize text-start text-body-2 font-weight-bold d-flex justify-start"
                    >
                      {{ key.replace(/_/g, "  ") }}
                    </span>
                    <div class="mt-2 d-flex align-center justify-start">
                      <div
                        class="icon-small light_primary d-flex justify-center align-center"
                      >
                        <v-icon color="primary" small>
                          {{ tripAnalyticsIcon[key] }}</v-icon
                        >
                      </div>

                      <span class="text-caption px-4 my-1">
                        <h3>{{ value }}</h3>
                      </span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="px-3" no-gutters>
              <v-col cols="12" class="px-1">
                <v-card class="rounded-lg" elevation="0">
                  <v-card-title>
                    <v-row no-gutters>
                      <v-col cols="6" class="d-flex justify-start align-center">
                        <h4 class="text-body-2 font-weight-bold">Attendance</h4>
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end align-center">
                        <v-btn
                          small
                          outlined
                          class="mr-4"
                          color="primary darken-2"
                          @click="setToday"
                        >
                          Today
                        </v-btn>
                        <v-btn
                          fab
                          text
                          x-small
                          color="grey darken-2"
                          @click="prev"
                        >
                          <v-icon small> mdi-chevron-left </v-icon>
                        </v-btn>
                        <v-btn
                          fab
                          text
                          x-small
                          color="grey darken-2"
                          @click="next"
                        >
                          <v-icon small> mdi-chevron-right </v-icon>
                        </v-btn>
                        <!-- <h4>{{ $refs.calendar }}</h4> -->
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-sheet height="528">
                          <v-calendar
                            class="calendarClass"
                            ref="calendar"
                            :value="focus"
                            v-model="focus"
                            color="primary"
                            @click:more="viewDay"
                            :events="tripEvents"
                            type="month"
                            @click:date="viewDay"
                          >
                          </v-calendar>
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </section>
        </v-col>

        <v-col cols="12" md="3" lg="3" class="d-flex flex-column">
          <v-card elevation="0" class="light_grey rounded-lg">
            <v-row no-gutters v-if="tripDetail.trip_details" class="pa-2">
              <v-col cols="4" md="3" lg="4" class="pa-2">
                <img
                  :src="TripImage1"
                  height="
                  100px
                  "
                  width="
                  100px
                  "
                />
              </v-col>
              <v-col class="pa-4 d-flex flex-column justify-space-between">
                <div>
                  <h4 class="text-caption white--text">Route Name</h4>
                  <h5 class="white--text">
                    {{ tripDetail.trip_details.route_name1 }}
                  </h5>
                </div>
                <div class="d-flex justify-space-between">
                  <div>
                    <h4 class="text-caption white--text">Route ID</h4>
                    <h5 class="white--text">
                      {{ tripDetail.trip_details.route_id1 }}
                    </h5>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="pa-0 ma-0" elevation="0">
            <v-row class="pa-0 ma-0" no-gutters>
              <v-col cols="12" md="12" lg="12" class="pa-4">
                <h4 class="text-caption">Organization Location</h4>
                <h5 class="text-body-2 font-weight-bold">
                  {{ tripDetail.trip_details.company_location }}
                </h5>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                class="d-flex flex-column justify-start px-4 pb-2"
              >
                <h4 class="text-caption">Route Type</h4>
                <h5 class="text-body-2 font-weight-bold">
                  {{ tripDetail.trip_details.route_type }}
                </h5>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                class="d-flex flex-column justify-end px-4 pb-2"
              >
                <h4 class="text-caption d-flex justify-end">Shift</h4>
                <h5 class="text-body-2 font-weight-bold d-flex justify-end">
                  {{ tripDetail.trip_details.shift }}
                </h5>
              </v-col>
            </v-row>
          </v-card>

          <v-card elevation="0" class="mt-4 rounded-lg">
            <v-card-title
              ><h4 class="text-body-2 font-weight-bold">Logs</h4></v-card-title
            >
            <v-divider></v-divider>
            <v-card-text>
              <div style="height: calc(100vh - 530px)" class="overflow-y-auto">
                <BaseLogs :logs="tripLogs" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </BaseDetailLayout>
</template>

<script>
import TripImage from "@/assets/trip-image.png";
import TripImage1 from "@/assets/trip-image1.png";
export default {
  name: "TripLayout",
  data() {
    return {
      TripImage,
      TripImage1,

      tripDetail: {
        trip_details: {
          trip_id: "TRP0024",
          route_name: "Naroda to Helmet Cross Road",
          route_name1: "Helmet Circle",
          route_id1: "STOP001",
          company_location: "401, Satya One Complex, Memnagar",
          route_type: "Pickup",
          shift: "09:00 - 17:00",
          driver: "Ravikant Misra",
          vehicle: "VCL0024",
          duration: "6 Months",
          stoppages: [
            {
              time: "9:00",
              address: "Shiv Shakti Society, Nikol Naroda, Ahmedabad, India",
              coordinates: "23.04, 72.53",
              stop_type: "Source",
            },
            {
              time: "9:10",
              address: "Shiv Shakti Society, Nikol Naroda, Ahmedabad, India",
              coordinates: "23.04, 72.53",
              stop_type: "Stop 1",
            },
            {
              time: "9:15",
              address: "Shiv Shakti Society, Nikol Naroda, Ahmedabad, India",
              coordinates: "23.04, 72.53",
              stop_type: "Stop 2",
            },
            {
              time: "9:20",
              address: "Shiv Shakti Society, Nikol Naroda, Ahmedabad, India",
              coordinates: "23.04, 72.53",
              stop_type: "Stop 3",
            },
            {
              time: "9:30",
              address: "Shiv Shakti Society, Nikol Naroda, Ahmedabad, India",
              coordinates: "23.04, 72.53",
              stop_type: "Stop 4",
            },
            {
              time: "9:45",
              address: "Shiv Shakti Society, Nikol Naroda, Ahmedabad, India",
              coordinates: "23.04, 72.53",
              stop_type: "Destination",
            },
          ],

          trip_analytics: {
            passengers: 200,
            stoppages: 2,
            start_date: "23/03/2023",
            end_date: "23/03/2023",
          },
        },
      },

      tripAnalyticsIcon: {
        passengers: "mdi-account-check",
        stoppages: "mdi-account-remove",
        start_date: "mdi-calendar-range",
        end_date: "mdi-calendar-range",
      },

      tripLogs: [
        {
          added_by: "Admin",
          created: "23/03/2023",
          message: "Driver has been assigned the vehicle(VH001)",
        },
        {
          added_by: "Admin",
          created: "23/03/2023",
          message: "Trip Trip-01 has been assigned to the driver.",
        },
      ],

      // calendar variables
      focus: "",
      events: [
        {
          name: "45 Present",
          date: "2023-03-01",
          color: "#780000",
        },
        {
          name: "03 Absent",
          date: "2023-03-02",
          color: "#00612C",
        },
        {
          name: "45 Present",
          date: "2023-03-03",
          color: "#780000",
        },
        {
          name: "03 Absent",
          date: "2023-03-03",
          color: "#00612C",
        },
        {
          name: "03 Absent",
          date: "2023-03-04",
          color: "#00612C",
        },
        {
          name: "45 Present",
          date: "2023-03-23",
          color: "#780000",
        },
        {
          name: "03 Absent",
          date: "2023-03-23",
          color: "#00612C",
        },
        {
          name: "45 Present",
          date: "2023-03-24",
          color: "#780000",
        },
        {
          name: "03 Absent",
          date: "2023-03-24",
          color: "#00612C",
        },
        {
          name: "45 Present",
          date: "2023-03-25",
          color: "#780000",
        },
        {
          name: "03 Absent",
          date: "2023-03-25",
          color: "#00612C",
        },
      ],
    };
  },
  computed: {
    tripEvents() {
      const events = this.events;
      events.forEach((element) => {
        element.start = element.date;
        element.color = element.color;
        element.name = element.name;
      });

      return events;
    },
  },
  methods: {
    // calendar methods
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    setToday() {
      this.focus = "";
    },

    viewDay({ date }) {
      this.focus = date;
      // this.type = "day";
    },
  },
  mounted() {
    // this.$refs.calendar.checkChange();
  },
};
</script>

<style >
</style>